import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TextField from 'material-ui/TextField'
import FlatButton from 'material-ui/FlatButton'

import rs from 'randomstring'

import styled from 'styled-components'

const Wrapper = styled.div`
    width: 100%;
    >* {
        display: flex;
        justify-content: space-between;
        align-items: center;
        >span:first-child {
            margin-right: 10px;
        }
    }
`

class MultiTextSave extends Component {

    state = {
        texts: this.props.texts
    };

    onChange = (event, index) => {
        const texts = [...this.state.texts]
        texts[index] = event.target.value
        this.setState({texts})
    };

    save = () => this.props.onSave(this.state.texts)

    hasChanges = () => this.state.texts.findIndex( (v, index) => v !== this.props.texts[index]) >= 0

    render() {
        return (
            <Wrapper>
                {this.props.texts.map( (val, index) =>  (
                    <div key={index}>
                        <span>{(index+1)}. </span>
                        <TextField
                            id={`${rs.generate({ length: 6 })}-multi-text-save`}
                            fullWidth
                            value={this.state.texts[index]}
                            onChange={event => this.onChange(event, index)}
                        />
                    </div>
                ))}
                <FlatButton label={this.props.label} primary fullWidth onClick={this.save} disabled={!this.hasChanges()} />
            </Wrapper>
        );
    }
}

MultiTextSave.propTypes = {
    texts: PropTypes.arrayOf(PropTypes.string).isRequired,
    label: PropTypes.string,
    onSave: PropTypes.func.isRequired
}

MultiTextSave.defaultProps = {
    label: 'save'
}

export default MultiTextSave
