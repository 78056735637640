import React from 'react'
import PropTypes from 'prop-types'

import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'

import styled from 'styled-components'

import TextField from 'material-ui/TextField'
import IconButton from 'material-ui/IconButton'
import CloseIcon from 'material-ui/svg-icons/navigation/close'

import MakeQuestion from '../MakeQuestion/container'

import Question from './Question'

import { questionSorter } from '../../../../utils/'
import theme from '../../../../stylesheets/vars'

const Wrapper = styled.div`
    >:nth-child(2) {
        height: 424px;
        overflow: auto;
        margin: 0 0 8px 0;
    }
`

const EmptyScreen = styled.div`
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const FilterWrapper = styled.div`
    border-radius: 4px;
    display: flex;
    align-items: stretch;
    width: calc(100% - 12px);
    margin: 8px 0;
    padding: 8px 0 8px 8px;
`

class Questions extends React.Component {

    state = {
        filter: '',
    }

    updateFilter = e => this.setState({ filter: e.target.value })
    removeFilter = () => this.setState({ filter: '' })

    render() {
        const { questions, scenario } = this.props
        const { filter } = this.state
        if (!scenario || !scenario.id) {
            return (
                <EmptyScreen>Please select a scenario</EmptyScreen>
            )
        }
        if (!questions || !questions.length) {
            return (
                <EmptyScreen>
                    You have no questions in this scenario. Press the button bellow to add.
                    <MakeQuestion resetOnSend />
                </EmptyScreen>
            )
        }
        return (
            <DndProvider backend={Backend}>
                <Wrapper>
                    <MakeQuestion resetOnSend />
                    <div>
                        {[...questions].
                            filter(question => question.question.toLowerCase().includes(filter.toLowerCase())).
                            sort(questionSorter).
                            map(question => <Question
                                key={question.questionid}
                                question={question}
                                removeQuestion={this.props.removeQuestion}
                                copyQuestion={this.props.copyQuestion}
                                moveUp={this.props.moveUp}
                                moveDown={this.props.moveDown}
                                isLast={question.questionnr === questions.length}
                            />)
                        }
                    </div>
                    <FilterWrapper>
                        <TextField
                            hintText="Filter questions"
                            value={filter}
                            onChange={this.updateFilter}
                            fullWidth
                            style={{background: 'transparent'}}
                        />
                        {filter &&
                            <IconButton style={{background: 'transparent'}} onClick={this.removeFilter}><CloseIcon color={theme.colors.error} /></IconButton>
                        }
                    </FilterWrapper>
                </Wrapper>
            </DndProvider>
        )
    }

}

Questions.propTypes = {
    scenario: PropTypes.object,
    questions: PropTypes.array,
    removeQuestion: PropTypes.func.isRequired,
    copyQuestion: PropTypes.func.isRequired,
    moveUp: PropTypes.func.isRequired,
    moveDown: PropTypes.func.isRequired,
};

Questions.defaultProps = {
    scenario: {},
    questions: [],
};

export default Questions
