import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { Link, withRouter } from 'react-router-dom'
import config from '../../../config'
 
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'
import TextField from 'material-ui/TextField'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import Checkbox from 'material-ui/Checkbox'

import theme from '../../../stylesheets/vars'
import {languages} from '../../../utils/constants'
import translations from '../../../utils/translations'

import Modal from '../../components/Modal/'

import { Link as LinkElem } from '../../components/SmallElems/'

import logo from '../../../assets/imagio_logo.png'
import sumoLogo from '../../../assets/sumo_logo_400.png'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    >h2 {
        font-family: "Droid Serif";
        font-style: italic;
        font-weight: normal;
    }

    .buttons {
        width: 250px;
        margin-top: 20px;
        &.big > button {
            height: 70px !important;
        }
    }

    >.imagio-logo {
        width: 300px;
    }

    >.sumo-logo {
        width: 50px;
    }
`

const StartWrapper = styled.div`
    display: flex;
    flex-direction: column;
    >:first-child {
        margin: 0;
    }
    >:last-child {
        margin-top: 12px !important;
    }
`

const LegalWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    >div:first-child {
        width: auto !important;
        flex: 0 0;
    }
    >span:last-child {
        cursor: pointer;
        >a {
            color: ${theme.colors.primary};
            text-decoration: none;
        }
    }
`

const getScenarioCodeFromUrl = url => {
    if (url.split('?').length !== 2) return
    const codeObject = url.split('?')[1].split('&').find(e => e.split('=')[0] === 'code')
    if (!codeObject) return undefined
    return codeObject.split('=')[1]
}

 const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  
class Welcome extends React.Component {

    constructor(props) {
        super(props)
        props.getCodes()
    }

    state = {
        code: getScenarioCodeFromUrl(this.props.location.search) || '',
        name: '',
        legal: false,
        gamecodePdf: '',
    }

    componentDidMount() {
        setTimeout(() => {
            const code = getScenarioCodeFromUrl(this.props.location.search)
            if (code && this.props.scenarioCodes.includes(code)) {
                this.openStartDialog()
            }
        }, 500)
    }

    setCode = e => this.setState({code: e.target.value})
    setName = e => this.setState({name: e.target.value})
    setLegal = () => this.setState(prev => ({legal: !prev.legal}))
    setGamecodePdf = e => this.setState({ gamecodePdf: e.target.value })

    startButtonRef = React.createRef()

    openStartDialog = () => {
        try {
            this.startButtonRef.current.refs.overlay.click()
        } catch(e) {
            console.log('openStartDialogError: ', e)
        }
    }

    startGame = () => {
        if (!this.state.legal) {
            this.props.legalWarning()
            return
        }
        if (!this.state.name) {
            this.props.nameWarning()
            return
        }
        this.props.startGame(this.state.code, this.state.name)
    }

    openPdfReport = () => {
        const { gamecodePdf } = this.state
        if (!gamecodePdf) return
        this.setState({ gamecodePdf: '' })
        this.props.verifyGameCode(gamecodePdf).then((res) => {
            if (res.data) {
                window.open(`${config.linkUrl}/ws/globalReport.php?gameid=${gamecodePdf}`)
                return
            }
            this.props.notifyFalseGameCode(translations['false gamecode'])
        }).catch(() => {
            this.props.notifyFalseGameCode(translations['false gamecode'])
        })
    }

    render () {
        const { errorText, changeLanguage, language, scenarioCodes, translations } = this.props
        const { code, legal, name, gamecodePdf } = this.state
        return (
            <Wrapper>
            
                <h2>{translations['Learning by imagination']}</h2>
            
                <img className="imagio-logo" src={logo} alt="imagio-logo" />
            
                <StartWrapper>
                    <TextField
                        autoFocus
                        floatingLabelText={translations['scenario code']}
                        value={code}
                        onChange={this.setCode}
                        errorText={errorText}
                        onKeyPress={e => code && e.key === 'Enter' && scenarioCodes.includes(code) && this.openStartDialog()}
                    />
                    <SelectField
                        floatingLabelText={translations['Language']}
                        value={language}
                        onChange={(e, i, language) => changeLanguage(language)}
                    >
                        {languages.map(l =>
                            <MenuItem key={l.short} value={l.short} primaryText={l.name} />
                        )}
                    </SelectField>
                    <Modal 
                        opener={
                            <RaisedButton
                                className="buttons big"
                                label={translations['Start game now']}
                                primary 
                                disabled={!code || !scenarioCodes.includes(code)}
                                ref={this.startButtonRef}
                            />
                        }
                        disabled={!code || !scenarioCodes.includes(code)}
                        title={`${translations['Enter your name (optional)']}:`}
                        dialogStyle={{width: '400px'}}
                    >
                        <StartWrapper>
                            <TextField
                                id="name-text-field"
                                autoFocus
                                fullWidth
                                value={name}
                                onChange={this.setName}
                                onKeyPress={e => code && e.key === 'Enter' && this.startGame()}
                            />
                            <RaisedButton label={translations['Start game']} primary onClick={() => this.startGame()}/>
                            <LegalWrapper>
                                <Checkbox
                                    label=""
                                    checked={legal}
                                    onCheck={this.setLegal}
                                />
                                <span onClick={this.setLegal}>
                                    {translations['legalCheckbox1']}<a href={`${config.linkUrl}/legal/`} target="_blank" rel="noopener noreferrer">
                                        <LinkElem>{translations['legalCheckbox2']}</LinkElem></a>{translations['legalCheckbox3']}
                                </span>
                            </LegalWrapper>
                        </StartWrapper>
                    </Modal>
                </StartWrapper>
            
                <Link to="feedback">
                    <RaisedButton className="buttons" label={translations['Open feedback board']} secondary />
                </Link>

                <Modal
                    opener={<RaisedButton className="buttons" label={translations['Report']} />}
                    title={translations['Report']}
                    dialogStyle={{width: '400px'}}
                    actions={[
                        <FlatButton key={1} label="Cancel" />,
                        <FlatButton
                            key={2}
                            label={translations['get pdf report']}
                            primary
                            disabled={!gamecodePdf}
                            onClick={this.openPdfReport}
                        />
                    ]}
                >
                    <TextField
                        id="pdf-report-text-field"
                        fullWidth
                        value={gamecodePdf}
                        onChange={this.setGamecodePdf}
                    />
                </Modal>
                <table>
                <tr>
                <td><RaisedButton onClick={() => openInNewTab('ws/overviewUser.php')} label={translations['Overview1']}/></td>
                <td><RaisedButton onClick={() => openInNewTab('ws/groupReportUser.php')}  label={translations['Overview2']}/></td>
                </tr>
                </table>         
                <h3>{translations['Powered by']}:</h3>
            
                <img className="sumo-logo" src={sumoLogo} alt="sumo-logo" />

            </Wrapper>
        )
    }
}

Welcome.propTypes = {
	language: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    translations: PropTypes.object.isRequired,
    changeLanguage: PropTypes.func.isRequired,
    startGame: PropTypes.func.isRequired,
    errorText: PropTypes.string.isRequired,
    scenarioCodes: PropTypes.array.isRequired,
    getCodes: PropTypes.func.isRequired,
    legalWarning: PropTypes.func.isRequired,
    nameWarning: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    verifyGameCode: PropTypes.func.isRequired,
    notifyFalseGameCode: PropTypes.func.isRequired,
}
// Welcome.defaultProps = {
// }

export default withRouter(Welcome)
