import React, { useState } from 'react'
import PropTypes from 'prop-types'

import IconButton from 'material-ui/IconButton'
import DeleteIcon from 'material-ui/svg-icons/action/delete'
import YouTubeIcon from '../../../assets/youtube-icon.png'

import TextField from 'material-ui/TextField'

import axios from 'axios'
import { StyledDropZone } from 'react-drop-zone'
import 'react-drop-zone/dist/styles.css'

import Player from '../MediaWithError/'

import styled from 'styled-components'
import theme from '../../../stylesheets/vars'

import config from '../../../config'

const Wrapper = styled.div`
	width: 100%;
	position: relative;
	min-height: 180px;
	margin-bottom: ${props => props.bottomPadded ? '48px' : 0};
	>:first-child {
		width: 100%;
		display: ${props => props.hasPreview ? 'inherit' : 'none'} !important;
	}
	>:nth-child(2) {
		position: absolute;
		top: 0;
		left: 0;
		width: calc(100% - 86px);
		height: calc(100% - 86px);
		z-index: ${props => props.hasPreview ? -1 : 1};
	}
	>:nth-child(3) {
		position: absolute !important;
		top: 5px;
		right: 5px;
		display: ${props => props.hasPreview ? 'inherit' : 'none'} !important;
	}
`

const YouTubeWrapper = styled.div`
	position: absolute;
	left: 0;
	bottom: -48px;
	width: 100%;
	display: flex;
	align-items: center;
	>img {
		width: 24px;
	}
`

const checkUrlIsYoutube = url => url.toLowerCase().includes('youtube.com/')

const Dropzone = ({ url, setUrl, takesVideo, takesImage, takesYT, targetFolder }) => {

	const processFile = (file) => {
        const isVideo = !file.type.includes('image')
        if ((isVideo && !takesVideo) || (!isVideo && !takesImage)) return
        const data = new FormData()
        data.append('file', file)
        data.append('targetFolder', targetFolder)

        const url = `${config.baseUrl}${targetFolder}/${file.name}`
        axios.post(`${config.baseUrl}uploadFile.php`, data).then(() => {
            setTempUrl(url)
            setUrl(url, isVideo)
        }).catch(e => {
            console.log('file upload error: ', e)
        })
    }

	const [tempUrl, setTempUrl] = useState(url)

	const removeFile = () => {
		setTempUrl('')
		setUrl('', false)
	}

	const setYTUrl = url => {
		if (!checkUrlIsYoutube(url)) return
		setTempUrl(url)
		setUrl(url)
	}

	return (
		<Wrapper hasPreview={!!tempUrl} bottomPadded={takesYT} >
			<Player playsInline src={tempUrl} />
			<StyledDropZone onDrop={processFile} />
			<IconButton onClick={removeFile}>
				<DeleteIcon color={theme.colors.error} />
			</IconButton>
			{(!tempUrl && takesYT) &&
				<YouTubeWrapper>
					<img src={YouTubeIcon} />
					<TextField
						id="dropzone-youtube"
						value={checkUrlIsYoutube(tempUrl) ? tempUrl : ''}
                        hintText="Youtube url"
                        onChange={e => setYTUrl(e.target.value)}
					/>
				</YouTubeWrapper>
			}
		</Wrapper>
	)
}

Dropzone.propTypes = {
	takesImage: PropTypes.bool,
	takesVideo: PropTypes.bool,
	takesYT: PropTypes.bool,
	targetFolder: PropTypes.string,
	url: PropTypes.string,
	setUrl: PropTypes.func,
}

Dropzone.defaultProps = {
	takesVideo: true,
	takesImage: true,
	takesYT: false,
	targetFolder: 'game_images',
	url: '',
	setUrl: () => {},
}

export default Dropzone
