import { connect } from 'react-redux'
import TheComponent from './index'

import actions from '../../../actions'
import { getGameError, getLanguage, getAllCodes, getTranslations } from '../../../selectors'

import { makeGetRequest } from '../../../api/'

const mapStateToProps = (state) => ({
    errorText: getGameError(state),
    language: getLanguage(state),
    scenarioCodes: getAllCodes(state),
    translations: getTranslations(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    legalWarning: () => dispatch({type: actions.OPEN_SNACKBAR, message: 'You have to accept legal warning, please.'}),
    nameWarning: () => dispatch({type: actions.OPEN_SNACKBAR, message: 'You have to enter a name, please.'}),
    getCodes: () => dispatch({type: actions.GET_CODES}),
    startGame: (scenariocode, playername) => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            alert('Media devices not supported')
            return
        }
        navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(stream => {
            window.mediaRecorder = new MediaRecorder(stream)
			dispatch({type: actions.GAME_START, scenariocode, playername})
        })
    },
    changeLanguage: (language) => dispatch({type: actions.CHANGE_LANGUAGE, language }),
    notifyFalseGameCode: () => dispatch({ type: actions.OPEN_SNACKBAR, message: 'Invalid code' }),
    verifyGameCode: gamecode => makeGetRequest('verifyGamecode.php', {gamecode}),
})

const Contained = connect(
    mapStateToProps,
    mapDispatchToProps
)(TheComponent)

export default Contained
