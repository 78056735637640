import React from 'react'
import PropTypes from 'prop-types'

import FlatButton from 'material-ui/FlatButton'
import TextField from 'material-ui/TextField'
import IconButton from 'material-ui/IconButton'
import DeleteIcon from 'material-ui/svg-icons/action/delete'

import {Modal} from '../../../components/Modal/'
import { makePostRequest, makeGetRequest } from '../../../../api/'

import styled from 'styled-components'
import theme from '../../../../stylesheets/vars'

const getGroups = (userid) => makeGetRequest('getScenarioGroups.php', {userid})
const saveGroupApi = (id, name, userid) => makePostRequest('saveScenarioGroup.php', {id, name, userid})
const deleteGroupApi = (id, userid) => makePostRequest('deleteScenarioGroup.php', {id, userid})

const Wrapper = styled.div`
`

const GroupWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    >*:not(:last-child) {
        margin-right: 1rem;
    }
`

const Group = styled.div`
    padding-left: 1rem;
    margin: 1rem;
    display: flex;
    align-items: center;
    border: 1px solid ${theme.colors.primary};
    background: ${props => !props.selected ? 'white' : theme.colors.primary};
    color: ${props => props.selected ? 'white' : theme.colors.primary};
    cursor: pointer;
    >*:not(:last-child) {
        margin-right: 1rem;
    }
    >* {
        padding: 0;
        line-height: 1rem;
    }
`

class Groups extends React.Component {

    state = {
        name: '',
        groups: [],
        selected: null,
    }

    updateName = e => this.setState({ name: e.target.value })

    setSelected = group => {
        this.setState({ selected: group.id, name: group.name })
    }

    componentDidMount() {
        getGroups(this.props.userid).then(res => {
            this.setState({ groups: res.data })
        }).catch(() => {})
    }

    saveGroup = () => {
        const {selected, name} = this.state
        this.setState({ name: '', selected: null })
        saveGroupApi(selected, name, this.props.userid).then(res => {
            this.props.onUpdate()
            if (selected) {
                this.setState(prev => ({ groups: prev.groups.map(g => {
                    if (g.id !== selected) return g
                    return {
                        ...g,
                        name,
                    }
                }) }))
                return
            }
            this.setState(prev => ({ groups: [{
                id: res.data,
                userid: this.props.userid,
                name,
            }, ...prev.groups]}))
        }).catch(() => {})
    }

    deleteGroup = (id) => {
        this.setState({ name: '', selected: null })
        deleteGroupApi(id, this.props.userid).then(() => {
            this.props.onUpdate()
            this.setState(prev => ({ groups: prev.groups.filter(g => g.id != id) }))
        }).catch(() => {})
    }

    render() {
        const { groups, name, selected } = this.state
        return (
            <Wrapper>
                <TextField
                    floatingLabelText="Name of group"
                    value={name}
                    onChange={this.updateName}
                />
                <FlatButton label="Create / Edit" onClick={this.saveGroup} disabled={!name} />
                <GroupWrapper>
                    {groups.map(group =>
                        <Group
                            key={group.id}
                            selected={selected == group.id}
                            onClick={() => this.setSelected(group)}
                        >
                            <span>{group.name}</span>
                            <Modal
                                opener={
                                    <IconButton><DeleteIcon color={theme.colors.error} /></IconButton>
                                }
                                title="Remove group?"
                                actions={[
                                    <FlatButton key={1} label="Cancel" />,
                                    <FlatButton key={2} label="Delete" onClick={() => this.deleteGroup(group.id)} />,
                                ]}
                            />
                        </Group>
                    )}
                </GroupWrapper>
            </Wrapper>
        )
    }

}

Groups.propTypes = {
    groups: PropTypes.array.isRequired,
    getScenarios: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    userid: PropTypes.number.isRequired,
}

Groups.defaultProps = {
}

export default Groups
