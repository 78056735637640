import { createSelector } from 'reselect'
import translations from '../utils/translations'

// const create = func => createSelector([func], x => x);

const game = state => state.game
const result = state => state.result
const user = state => state.user
const users = state => state.users
const language = state => state.language
const codes = state => state.codes

export const getAllCodes = createSelector([codes], c => c)

export const getLanguage = createSelector([language], l => l)
export const getTranslations = createSelector([language], l => translations[l])

export const getIsGameRunning = createSelector([game], g => g.isRunning)
export const getGameError = createSelector([game], g => g.error)
export const getGameState = createSelector([game], g => g.state)

export const getCodeExists = createSelector([result], r => r.codeExists)
export const getCodeError = createSelector([result], r => r.error)
export const getResults = createSelector([result], r => r.data)

export const getAdmin = createSelector([user], u => u.admin)
export const getScenarios = createSelector([user], u => u.scenarios)
export const getSelectedScenario = createSelector([user], u => {
	if (!u || !u.scenarios || !Array.isArray(u.scenarios)) return null
	return u.scenarios.find(s => s.id === u.selectedScenario)
})
export const getSelectedScenarioQuestions = createSelector([user], u => {
	if (!u || !u.scenarios) {
		return []
	}
	const sel = u.scenarios.find(s => s.id === u.selectedScenario)
	if (!sel) return []
	return sel.questions
})
export const getUsers = createSelector([users], u => u)
export const getPeers = createSelector([users, user], (users, admin) => users.filter(u => u.id !== admin.id))
