import actions from '../actions'
import {languages} from '../utils/constants'

// September 2022
function languageReducer(state = languages[0].short, action) {
	switch (action.type) {
		case actions.CHANGE_LANGUAGE: {
      		return action.language
		}
    default: return state
  }
}

export default languageReducer
