export default {
    GET_CODES: 'GET_CODES',

    GAME_START: 'GAME_START',     
    GAME_QUIT: 'GAME_QUIT',
    GAME_FINISH: 'GAME_FINISH',
    GAME_NEXT_ROUND: 'GAME_NEXT_ROUND',

    SAVE_RECORDING: 'SAVE_RECORDING',
    SAVE_TRANSCRIPT: 'SAVE_TRANSCRIPT',
    SAVE_RANKINGS: 'SAVE_RANKINGS',

    GET_LAST_GAMEID: 'GET_LAST_GAMEID',
    GET_RESULTS: 'GET_RESULTS',
    REMOVE_RESULT_DATA: 'REMOVE_RESULT_DATA',
    
    LOGOUT_ADMIN: 'LOGOUT_ADMIN',
    LOGIN_ADMIN: 'LOGIN_ADMIN',
    GET_USERS: 'GET_USERS',
    UPDATE_USER: 'UPDATE_USER',
    CREATE_USER: 'CREATE_USER',
    DELETE_USER: 'DELETE_USER',

    MAKE_QUESTION: 'MAKE_QUESTION',
    REMOVE_QUESTION: 'REMOVE_QUESTION',
    MOVE_UP_QUESTION: 'MOVE_UP_QUESTION',
    MOVE_DOWN_QUESTION: 'MOVE_DOWN_QUESTION',
    COPY_QUESTION: 'COPY_QUESTION',
    
    MAKE_SCENARIO: 'MAKE_SCENARIO',
    GET_SCENARIOS: 'GET_SCENARIOS',
    SET_SELECTED_SCENARIO: 'SET_SELECTED_SCENARIO',
    REMOVE_SCENARIO: 'REMOVE_SCENARIO',
    COPY_SCENARIO: 'COPY_SCENARIO',
    
    OPEN_SNACKBAR: 'OPEN_SNACKBAR',
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
};