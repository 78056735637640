import { takeLatest, takeEvery, put, call } from 'redux-saga/effects'
import actions from '../actions'
import api from '../api';

const SUCCESS = '_SUCCESS';
const ERROR = '_ERROR';

function* generic(...argums) {
    const [func, params] = argums;
    const {type} = params;
	try {
        const data = yield call(api[func], params);
        console.log('saga data: ', data, type)
        yield put({ type: actions[type] + SUCCESS, data });
    } catch (error) {
        console.error('saga fail: ', error);
        yield put({ type: actions[type] + ERROR, error })
    }
}

function* getScenarios(...argums) {
    yield put({ type: actions.GET_SCENARIOS })
}

function* getUsers(...argums) {
    console.log('get users', argums)
    yield put({ type: actions.GET_USERS })
}

export default function* sagas() {
    yield takeLatest(actions.MAKE_QUESTION, generic, 'makeQuestion');
    yield takeLatest(actions.COPY_QUESTION, generic, 'copyQuestion');
    yield takeLatest(actions.REMOVE_QUESTION, generic, 'removeQuestion');
    yield takeLatest(actions.MOVE_UP_QUESTION, generic, 'moveUpQuestion');
    yield takeLatest(actions.MOVE_DOWN_QUESTION, generic, 'moveDownQuestion');
    yield takeLatest(actions.MOVE_DOWN_QUESTION + '_SUCCESS', getScenarios);
    yield takeLatest(actions.MOVE_UP_QUESTION + '_SUCCESS', getScenarios);
    yield takeLatest(actions.MAKE_QUESTION + '_SUCCESS', getScenarios);
    yield takeLatest(actions.REMOVE_QUESTION + '_SUCCESS', getScenarios);
    yield takeLatest(actions.COPY_QUESTION + '_SUCCESS', getScenarios);

    yield takeLatest(actions.GET_CODES, generic, 'getCodes');
    yield takeLatest(actions.MAKE_SCENARIO, generic, 'makeScenario');
    yield takeLatest(actions.MAKE_SCENARIO + '_SUCCESS', getScenarios);
    yield takeLatest(actions.REMOVE_SCENARIO, generic, 'removeScenario');
    yield takeLatest(actions.REMOVE_SCENARIO + '_SUCCESS', getScenarios);
    yield takeLatest(actions.COPY_SCENARIO, generic, 'copyScenario');
    yield takeLatest(actions.COPY_SCENARIO + '_SUCCESS', getScenarios);
    yield takeLatest(actions.GET_SCENARIOS, generic, 'getScenarios');

    yield takeLatest(actions.LOGOUT_ADMIN, generic, 'logoutAdmin');
    yield takeLatest(actions.LOGIN_ADMIN, generic, 'loginAdmin');
    yield takeLatest(actions.LOGIN_ADMIN + '_SUCCESS', getUsers);
    yield takeLatest(actions.GET_USERS, generic, 'getUsers');
    yield takeLatest(actions.UPDATE_USER, generic, 'updateUser');
    yield takeLatest(actions.UPDATE_USER + '_SUCCESS', getUsers);
    yield takeLatest(actions.CREATE_USER, generic, 'createUser');
    yield takeLatest(actions.CREATE_USER + '_SUCCESS', getUsers);
    yield takeLatest(actions.DELETE_USER, generic, 'deleteUser');
    yield takeLatest(actions.DELETE_USER + '_SUCCESS', getUsers);

    yield takeLatest(actions.GAME_START, generic, 'getGame');
    yield takeLatest(actions.SAVE_RECORDING, generic, 'saveRecording');
    yield takeLatest(actions.GET_RESULTS, generic, 'getResults');
    yield takeLatest(actions.SAVE_TRANSCRIPT, generic, 'saveTranscript');
    yield takeLatest(actions.SAVE_RANKINGS, generic, 'saveRankings');
}