import React, { useState } from 'react'
import PropTypes from 'prop-types'

import TextField from 'material-ui/TextField'
import RaisedButton from 'material-ui/RaisedButton'

import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    >:first-child {
        background: transparent !important;
        margin-bottom: 12px;
    }
`

const GetCodeScreen = ({ getResults, errorText, translations }) => {
    const [code, setCode] = useState('')
    return (
        <Wrapper>
            <TextField
                autoFocus
                floatingLabelText={translations['Insert game code here']}
                value={code}
                onChange={e => setCode(e.target.value)}
                onKeyPress={e => code.length === 4 && e.key === 'Enter' && getResults(code)}
                errorText={errorText}
            />
            <RaisedButton
                label={translations['Display results']}
                primary
                onClick={() => getResults(code)}
                disabled={code.length !== 4}
            />
        </Wrapper>
    )
}

GetCodeScreen.propTypes = {
	getResults: PropTypes.func.isRequired,
    errorText: PropTypes.string.isRequired,
    translations: PropTypes.object.isRequired,
}

export default GetCodeScreen
