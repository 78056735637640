import actions from '../actions'

function codesReducer(state = [], action) {
	switch (action.type) {
		case `${actions.GET_CODES}_SUCCESS`: {
			return action.data.data.map(d => d.scenariocode)
		}
    default: return state
  }
}

export default codesReducer
