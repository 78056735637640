import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom'

import Dialog from 'material-ui/Dialog'

import styled from 'styled-components'

const OpenerWrapper = styled.div`
    pointer-events: ${props => props.disabled ? 'none' : 'inherit'};
`

export const Modal = ({ disabled, opener, title, className, closeOnAction, actions, modal, children, location, history, dialogStyle, onOpen }) => {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (location.pathname) setOpen(false)
    }, [location.pathname])

    const actionWithClose = action => (
        <action.type
            {...action.props}
            onClick={() => {
                if (action.props.onClickCallbackClose) {
                    action.props.onClickCallbackClose(() => setOpen(false))
                    return
                }
                setOpen(false)
                if (!action.props.onClick) return
                action.props.onClick()
            }}
        />
    )

    // must pass since Material UI removes children from router
    const childrenWithHistory = children => {
        if (!history.location) return children
        if (Array.isArray(children)) {
            return children.map((child, index) => React.cloneElement(child, { history, key: child.id || index }))
        }
        if (children.type) return React.cloneElement(children, { history })
        return children
    }

    const openAndCall = () => {
        setOpen(true)
        if (onOpen) onOpen()
    }

    return (
        <div>
            <OpenerWrapper
                onClick={openAndCall}
                disabled={disabled}
            >
                {opener}
            </OpenerWrapper>
            <Dialog
                style={{zIndex: 1999, top: -50, maxHeight: '70vh'}}
                contentStyle={dialogStyle}
                title={title}
                autoDetectWindowHeight
                autoScrollBodyContent
                contentClassName={className}
                bodyStyle={{maxHeight: '70vh !important'}}
                actions={closeOnAction ? actions.map(actionWithClose) : actions}
                modal={modal}
                open={open}
                onRequestClose={() => setOpen(false)}
            >
                {childrenWithHistory(children)}
            </Dialog>
        </div>
    )
}

Modal.propTypes = {
    dialogStyle: PropTypes.object,
    title: PropTypes.string,
    opener: PropTypes.element.isRequired,
    children: PropTypes.any.isRequired,
    actions: PropTypes.arrayOf(PropTypes.element),
    closeOnAction: PropTypes.bool,
    className: PropTypes.string,
    modal: PropTypes.bool,
    disabled: PropTypes.bool,
    onOpen: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    // router
    location: PropTypes.object,
    history: PropTypes.object,
}

Modal.defaultProps = {
    dialogStyle: {},
    title: '',
	actions: [],
    closeOnAction: true,
    className: '',
    modal: false,
    disabled: false,
    onOpen: false,
    location: {},
    history: {},
}

export default withRouter(Modal)
