import { connect } from 'react-redux'
import TheComponent from './index'

import actions from '../../../actions'
import { getIsGameRunning, getGameState, getLanguage, getTranslations } from '../../../selectors'

const mapStateToProps = (state) => ({
    gameState: getGameState(state),
    isGameRunning: getIsGameRunning(state),
    language: getLanguage(state),
    translations: getTranslations(state),
})

const mapDispatchToProps = (dispatch) => ({
    next: () => dispatch({type: actions.GAME_NEXT_ROUND}),
    finish: () => dispatch({type: actions.GAME_FINISH}),
    saveAudio: ( file, gameid, step, questionid, transcript, language ) => dispatch({type: actions.SAVE_RECORDING, file, gameid, step, questionid, transcript, language }),
})

const Contained = connect(
    mapStateToProps,
    mapDispatchToProps
)(TheComponent)

export default Contained
