import { connect } from 'react-redux'
import TheComponent from './index'

import actions from '../../../../actions/'
import {getUsers, getAdmin} from '../../../../selectors/'

const mapStateToProps = (state) => ({
    admin: getAdmin(state),
    users: getUsers(state),
})

const mapDispatchToProps = (dispatch) => ({
	createUser: () => dispatch({type: actions.CREATE_USER}),
	completeCreateUser: () => dispatch({type: actions.OPEN_SNACKBAR, message: 'User successfully created'}),
})

const Contained = connect(
    mapStateToProps,
    mapDispatchToProps
)(TheComponent)

export default Contained
