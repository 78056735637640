import { combineReducers } from 'redux';
import actions from '../actions';

function gameRunning(state=false, action) {
	switch (action.type) {
		case `${actions.GAME_START}_SUCCESS`: return !action.data.data.error
    case `${actions.GAME_START}_ERROR`:
    case actions.GAME_QUIT:
		case actions.GAME_FINISH: return false
    default: return state
  }
}

function error(state='', action) {
  switch (action.type) {
    case `${actions.GAME_START}_SUCCESS`: {
      if (action.data.data.error) return action.data.data.message
      return ''
    }
    case `${actions.GAME_START}_ERROR`:
    case actions.GAME_QUIT:
    case actions.GAME_FINISH: return ''
    default: return state
  }
}

function gameState(state = {}, action) {
    switch (action.type) {
        case `${actions.GAME_START}_SUCCESS`: {
          if (action.data.data.error) return {
            step: 0,
            error: action.data.data.message
          }
          return {
          step: 0,
          scenario: {
            ...action.data.data[0],
            countdown: action.data.data[0].countdown == 1,
          },
          game: action.data.data[1],
          questions: action.data.data[2].map(q => ({
            ...q,
            showImage: q.showImage == 1,
            showText: q.showText == 1,
            showRecordingSymbol: q.showRecordingSymbol == 1,
          })),
          code: action.data.data[1].gamecode,
          gameId: action.data.data[1].gameid,
        }}
        case actions.GAME_NEXT_ROUND: return {
           ...state,
           step: state.step + 1
        }
        case actions.GAME_FINISH: return {
           ...state,
           step: 0
        }
        default: return state
    }
}

export default combineReducers({
  	isRunning: gameRunning,
    error,
  	state: gameState
});