import React from 'react'
// import PropTypes from 'prop-types'

import MicIcon from 'material-ui/svg-icons/av/mic'

import './style.scss';

const Pulse = () => (
    <div className="pulse">
        <div className="pulse-center" />
        <div className="pulse-pulsar" />
        <MicIcon color="white" />
    </div>
)

export default Pulse