import { connect } from 'react-redux'
import TheComponent from './index';

import actions from '../../../../actions';
import {getSelectedScenarioQuestions, getSelectedScenario } from '../../../../selectors';

const mapStateToProps = (state) => ({
    scenario: getSelectedScenario(state),
    questions: getSelectedScenarioQuestions(state),
})

const mapDispatchToProps = (dispatch) => ({
	copyQuestion: (id) => dispatch({type: actions.COPY_QUESTION, id}),
	removeQuestion: (id) => dispatch({type: actions.REMOVE_QUESTION, id}),
	moveUp: (id) => dispatch({type: actions.MOVE_UP_QUESTION, id}),
	moveDown: (id) => dispatch({type: actions.MOVE_DOWN_QUESTION, id}),
})

const Contained = connect(
    mapStateToProps,
    mapDispatchToProps
)(TheComponent)

export default Contained;