import React from 'react'
import PropTypes from 'prop-types'

import GetCodeScreen from './getCode/container'
import ResultsView from './ResultsView/container'

import styled from 'styled-components'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	width: calc(100% - 40px);
	>:first-child {
		margin-bottom: 20px;
		width: 100%;
	}
`

const Results = props => (
    <Wrapper>
        {!props.codeExists ? (
            <GetCodeScreen />
    	) : (
        	<ResultsView />
    	)}
    </Wrapper>
)

Results.propTypes = {
	codeExists: PropTypes.bool.isRequired,
}

export default Results
