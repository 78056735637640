import React from 'react'
import PropTypes from 'prop-types'

import FlatButton from 'material-ui/FlatButton'

import Speech from '../Speech/'

import styled from 'styled-components'
// import theme from '../../../stylesheets/vars'

import Pulse from '../Pulse/'

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	>div:first-child {
		min-height: 100px;
	}
`

class MicTest extends React.Component {

	resetTranscript = () => {
		this.props.resetTranscript()
	} 

	render() {
		const { transcript } = this.props
		return (
			<Wrapper>
				<div>{transcript}</div>
				<Pulse />
				<FlatButton label="Reset" onClick={this.resetTranscript} />
			</Wrapper>
		)
	}
}

MicTest.propTypes = {
	transcript: PropTypes.string,
	resetTranscript: PropTypes.func.isRequired,
}
MicTest.defaultProps = {
	transcript: '',
}

export default Speech(MicTest)
