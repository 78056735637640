import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import NoImage from '../../../assets/no-image.png'

import ReactPlayer from 'react-player'

import { checkIsVideo } from '../../../utils/'

const NoClick = styled.div`
	pointer-events: ${props => props.noControl ? 'none' : 'inherit'};
	width: auto;
`

const MediaWithError = ({ src, alt, isvideo, autoPlay, noControl }) => {
	const [ error, setError ] = useState(null)

	useEffect(() => {
		setError(false)
	}, [src])
	
	if (error) {
		return (
			<img src={NoImage} />
		)
	}
	const isReallyVideo = checkIsVideo(src) || isvideo
	if (isReallyVideo) {
		return (
			<NoClick noControl={noControl}>
				<ReactPlayer
					url={src}
					playing={autoPlay}
					inline
					controls={true}
					width='100%'
          			height='360px'
					onError={() => setError(true)}
				/>
			</NoClick>
		)
	}
	return (
		<img src={src} alt={alt} onError={() => setError(true)} />
	)
}

MediaWithError.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string,
	isvideo: PropTypes.bool,
	autoPlay: PropTypes.bool,
	noControl: PropTypes.bool,
}
MediaWithError.defaultProps = {
	src: '',
	alt: 'no image',
	isvideo: false,
	autoPlay: false,
	noControl: false,
}

export default MediaWithError