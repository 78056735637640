import { connect } from 'react-redux'
import TheComponent from './index'

import { getTranslations } from '../../selectors/'

const mapStateToProps = (state) => ({
     translations: getTranslations(state),
})

const Contained = connect(
    mapStateToProps,
)(TheComponent)

export default Contained
