import React from 'react'
import PropTypes from 'prop-types'

import FlatButton from 'material-ui/FlatButton'
import TextField from 'material-ui/TextField'

import styled from 'styled-components'
import theme from '../../../../../stylesheets/vars'

import Dropzone from '../../../../components/Dropzone/'
import Modal from '../../../../components/Modal/'

const Wrapper = styled.div`
    width: calc(100% - 16px);
    padding: 8px;
    margin: 6px 0;
    border: 1px solid ${theme.colors.transparentGray};
    ${theme.shadows.basic};
    overflow: hidden;
    >div:first-child {
        display: flex;
        >:first-child {
            width: 60%;
            height: 0%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            >* {
                margin: 20px 8px;
                min-width: 45%;
                width: auto;
                &:last-child {
                    flex-grow: 1;
                }
            }
        }
        >:last-child {
            width: 40%;
        }
    }
`

const Buttons = styled.div`
    width: 100%;
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
    >* {
        margin-left: 16px;
    }
`

class SingleAdmin extends React.Component {

    state = {
        username: this.props.user.username || '',
        password: '',//this.props.user.pwd || '',
        fullname: this.props.user.fullname || '',
        companyname: this.props.user.companyname || '',
        email: this.props.user.email || '',
        logo: this.props.user.logo || '',
    }

    updateUserName = e => this.setState({ username: e.target.value })
    updatePassword = e => this.setState({ password: e.target.value })
    updateFullname = e => this.setState({ fullname: e.target.value })
    updateEmail = e => this.setState({ email: e.target.value })
    updateLogo = logo => this.setState({ logo })

    updateUser = () => {
        const { username, password, fullname, email, logo } = this.state
        this.props.updateUser(this.props.user.id, username, password, fullname, email, logo)
        this.props.completeUpdateUser()
        this.setState({ password: '' })
    }

    deleteUser = () => {
        this.props.deleteUser(this.props.user.id)
        this.props.completeDeleteUser()
    }

    render() {
        const { user, isAdmin, canDelete } = this.props
        const { username, password, fullname, companyname, email, logo } = this.state
        return (
            <Wrapper>
                <div>
                    <div>
                        <TextField
                            id={`${user.id}-admin-single-name`}
                            value={username}
                            floatingLabelText="Username"
                            onChange={this.updateUserName}
                        />
                        <TextField
                            id={`${user.id}-admin-single-pwd`}
                            value={password}
                            floatingLabelText="Password"
                            type="password"
                            autoComplete="new-password"
                            errorText="Password field is empty to hide password, it will only update the password if you type a new password"
                            errorStyle={{color: theme.colors.primary}}
                            onChange={this.updatePassword}
                        />
                        <TextField
                            id={`${user.id}-admin-single-fullname`}
                            value={fullname}
                            floatingLabelText="Full name"
                            onChange={this.updateFullname}
                        />
                        <TextField
                            id={`${user.id}-admin-single-companyname`}
                            value={companyname}
                            floatingLabelText="Company name"
                            disabled
                        />
                        <TextField
                            id={`${user.id}-admin-single-email`}
                            value={email}
                            floatingLabelText="Email"
                            onChange={this.updateEmail}
                        />
                    </div>
                    <Dropzone targetFolder="user_images" setUrl={this.updateLogo} url={logo} />
                </div>
                <Buttons>
                    <FlatButton primary label="Update this user" onClick={this.updateUser} disabled={!username} />
                    <Modal
                        disabled={isAdmin}
                        opener={<FlatButton secondary label="Delete this user" disabled={isAdmin || !canDelete} />}
                        title="Do you really want to delete this user?"
                        actions={[
                            <FlatButton key={1} label="Cancel" />,
                            <FlatButton key={2} label="Delete" primary onClick={this.deleteUser} />,
                        ]}
                    >
                        Please confirm
                    </Modal>
                </Buttons>
            </Wrapper>
        )
    }

}            

SingleAdmin.propTypes = {
    user: PropTypes.object.isRequired,
    updateUser: PropTypes.func.isRequired,
    completeUpdateUser: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    completeDeleteUser: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    canDelete: PropTypes.bool.isRequired,
}

SingleAdmin.defaultProps = {
}

export default SingleAdmin
