import { connect } from 'react-redux'
import TheComponent from './index'

import actions from '../../../../actions'
import {getScenarios, getSelectedScenario, getPeers, getAdmin } from '../../../../selectors'

const mapStateToProps = (state) => ({
    selectedScenario: getSelectedScenario(state),
    scenarios: getScenarios(state),
    peers: getPeers(state),
    admin: getAdmin(state),
})

const mapDispatchToProps = (dispatch) => ({
	setSelectedScenario: (id) => dispatch({type: actions.SET_SELECTED_SCENARIO, id}),
	removeScenario: (id) => dispatch({type: actions.REMOVE_SCENARIO, id}),
	copyScenario: (id, code) => dispatch({type: actions.COPY_SCENARIO, id, code})
})

const Contained = connect(
    mapStateToProps,
    mapDispatchToProps
)(TheComponent)

export default Contained
