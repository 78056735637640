import React from 'react'

import styled from 'styled-components'
import theme from '../../stylesheets/vars'

import { Switch, Route } from 'react-router-dom'

import Welcome from './welcome/container'
import Results from './results/container'
import Admin from './admin/container'
import Game from './game/container'

const Wrapper = styled.div`
	width: 90%;
	max-width: 1200px;
	margin: 100px auto 20px auto;
	border-radius: 5px;
	padding: 10px;
	background-color: ${theme.colors.transparentWhite} !important;
	>div {
		width: 100%;
	}
`


// todo: maybe convert all to nextjs?
const ScreenArea = () => {
	return (
		<Wrapper>
			<Switch>
				<Route path="/feedback"><Results /></Route>
				/*<Route path="/imagio2/feedback"><Results /></Route>*/
				<Route path="/game"><Game /></Route>
				/*<Route path="/imagio2/game"><Game /></Route>*/
				<Route path="/backend"><Admin /></Route>
				/*<Route path="/imagio2/backend"><Admin /></Route>*/
				{/*<Route path="/imagio2/:scenariocode"><Welcome /></Route>*/}
				<Route path="/"><Welcome /></Route>
			</Switch>
		</Wrapper>
	)
}

export default ScreenArea