import { combineReducers } from 'redux';
import actions from '../actions/';

function users(state = [], action) {
	switch (action.type) {
		case `${actions.GET_USERS}_SUCCESS`: {
			return action.data.data
		}
    	default: return state
	}
}

export default users