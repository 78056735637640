import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import {List} from 'material-ui/List'
import FlatButton from 'material-ui/FlatButton'
import Checkbox from 'material-ui/Checkbox'
import IconButton from 'material-ui/IconButton'
import Snackbar from 'material-ui/Snackbar'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'

import GroupIcon from 'material-ui/svg-icons/social/group'
import DeleteIcon from 'material-ui/svg-icons/action/delete'
import ShareIcon from 'material-ui/svg-icons/social/share'
import EditIcon from 'material-ui/svg-icons/image/edit'
import CopyIcon from 'material-ui/svg-icons/content/content-copy'
import ListIcon from 'material-ui/svg-icons/action/list'

import Modal from '../../../components/Modal/'
import MakeScenario from '../MakeScenario/container'
import GroupManager from './groupManager'

import { RandomCode } from '../../../../utils/'

import config from '../../../../config'
import theme from '../../../../stylesheets/vars'

import {makeGetRequest} from '../../../../api/'

const Wrapper = styled.div`
    >:nth-child(2) {
        height: 400px;
        overflow: auto;
        margin: 0 0 8px 0;
    }
`

const WrapText = styled.div`
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const ScenarioWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 16px);
    padding: 16px;
    cursor: pointer;
    border-bottom: 1px solid ${theme.colors.primary};
    background: ${props => props.selected ? theme.colors.transparentGray : ''};
    >:first-child {
        width: 48px !important;
        flex: 0 0 auto;
    }
    >:nth-child(2) {
        flex-grow: 2;
        >:last-child {
            font-size: 14px;
            color: silver;
        }
    }
    >:last-child {
        display: flex;
    }
`

const SelectedMenu = styled.div`
    width: calc(100% - 16px);
    margin: 0 0 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    >:last-child {
        display: flex;
    }
`

const getGroups = (userid) => makeGetRequest('getScenarioGroups.php', {userid})

class Scenarios extends React.Component {

    state = {
        selectedScenarios: [],
        openSnackbar: false,
        snackbarMessage: '',
        selectedGroup: 0,
        groups: [],
    }

    fetchGroups = () => {
        console.log('fetchGroups')
        getGroups(this.props.admin.id).then(res => {
            console.log('ff', res)
            this.setState({ groups: res.data })
        }).catch(() => {})
    }

    componentDidMount() {
        this.fetchGroups()
    }

    updateSelectedGroup = (e,i, selectedGroup) => this.setState({ selectedGroup })

    selectScenario = scenarioId => this.props.setSelectedScenario(scenarioId)
    removeScenario = scenarioId => this.props.removeScenario(scenarioId)
    removeBulkScenario = () => {
        this.state.selectedScenarios.forEach(s => this.props.removeScenario(s))
        this.setState({ selectedScenarios: [] })
    }
    shareScenario = () => {}
    shareBulkScenarios = () => {}

    openSnackbarCopy = () => {
        this.setState({ snackbarMessage: 'Copied link to clipboard', openSnackbar: true })
    }
    closeSnackbar = () => this.setState({ openSnackbar: false })

    selectAll = () => {
        if (this.state.selectedScenarios.length === this.props.scenarios.length) {
            this.setState({ selectedScenarios: [] })
            return
        }
        this.setState({ selectedScenarios: this.props.scenarios.map(s => s.id) })
    }
    toggleScenario = id => {
        this.props.setSelectedScenario(id)
        const selectedScenarios = [...this.state.selectedScenarios]
        if (selectedScenarios.includes(id)) {
            this.setState({ selectedScenarios: selectedScenarios.filter(s => s !== id) })
            return
        }
        this.setState({ selectedScenarios: [...selectedScenarios, id] })
    }

    copyScenario = id => this.props.copyScenario(id, RandomCode())

    render() {
        const { selectedScenario, scenarios, peers, admin } = this.props
        const { selectedScenarios, groups, selectedGroup } = this.state
        return (
            <Wrapper>
                <MakeScenario />
                <List>
                    {scenarios.filter(s => selectedGroup == 0 || selectedGroup == s.groupid).map(({id, scenarioname, scenariocode, questions, countdown, levelLogic, textToSpeech, logo, groupid}) => (
                        <ScenarioWrapper key={id} selected={id === selectedScenario.id} onClick={() => this.selectScenario(id)}>
                            <Checkbox
                                checked={selectedScenarios.includes(id)}
                                onCheck={(e) => {
                                    e.stopPropagation()
                                    this.toggleScenario(id)
                                }}
                            />
                            <div>
                                <WrapText>{scenarioname} ( {questions.length} )</WrapText>
                                <div>{scenariocode}</div>
                            </div>
                            <div>
                                <IconButton><ListIcon color={theme.colors.primary} /></IconButton>
                                <MakeScenario
                                    opener={<IconButton><EditIcon color={theme.colors.primary} /></IconButton>}
                                    id={id}
                                    name={scenarioname}
                                    code={scenariocode}
                                    countdown={countdown}
                                    levelLogic={levelLogic}
                                    textToSpeech={textToSpeech}
                                    logo={logo}
                                    groupid={groupid}
                                    title="Edit"
                                />
                                {(false && peers && peers.length) && (
                                    <Modal
                                        title="Share this scenario?"
                                        opener={<IconButton><ShareIcon color={theme.colors.primary} /></IconButton>}
                                        closeOnAction
                                        actions={[
                                            <FlatButton key={1} label="Cancel" />,
                                            <FlatButton key={2} label="Share" primary onClick={() => this.shareScenario(id)} />
                                        ]}
                                    >
                                        Are you sure you want to share this scenario with {peers.map(peer => peer.fullname).join(', ')}?
                                    </Modal>
                                )}
                                <CopyToClipboard text={`${config.linkUrl}?code=${scenariocode}`} onCopy={this.openSnackbarCopy}>
                                    <IconButton><ShareIcon color={theme.colors.primary} /></IconButton>
                                </CopyToClipboard>
                                <Modal
                                    opener={<IconButton><CopyIcon color={theme.colors.primary} /></IconButton>}
                                    title="Copy this scenario and it's questions?"
                                    closeOnAction
                                    actions={[
                                        <FlatButton key={1} label="Cancel" />,
                                        <FlatButton key={2} label="Copy" primary onClick={() => this.copyScenario(id)} />
                                    ]}
                                >
                                    All the questions will be copied and the new scenario will get a new code.
                                </Modal>
                                <Modal
                                    title="Remove this scenario?"
                                    opener={<IconButton><DeleteIcon color={theme.colors.error} /></IconButton>}
                                    closeOnAction
                                    actions={[
                                        <FlatButton key={1} label="Cancel" />,
                                        <FlatButton key={2} label="Remove" primary onClick={() => this.removeScenario(id)} />
                                    ]}
                                >
                                    Are you sure you want to delete this scenario?
                                </Modal>
                            </div>
                        </ScenarioWrapper>
                    ))}
                </List>
                <SelectedMenu>
                    <Checkbox onCheck={this.selectAll} checked={scenarios.length && scenarios.length === selectedScenarios.length} />
                    <div>
                        <SelectField
                            style={{width: '180px'}}
                            floatingLabelText="Group"
                            value={selectedGroup.toString()}
                            onChange={this.updateSelectedGroup}
                        >
                            <MenuItem value="0" primaryText="All groups" />
                            {groups.map(g =>
                                <MenuItem key={g.id} value={g.id} primaryText={g.name} />
                            )}
                        </SelectField>
                        <Modal
                            title="Share selected scenarios?"
                            disabled={!selectedScenarios.length}
                            opener={
                                <IconButton
                                    tooltip="Share selected"
                                    disabled={!selectedScenarios.length}
                                >
                                    <ShareIcon color={theme.colors.primary} />
                                </IconButton>
                            }
                            actions={[
                                <FlatButton key={1} label="Cancel" />,
                                <FlatButton key={2} primary label="Share" onClick={this.shareBulkScenarios} />
                            ]}
                        >
                            Do you really want to share these scenarios with {peers.map(peer => peer.fullname).join(', ')}?
                        </Modal>
                        <Modal
                            title="Remove selected scenarios?"
                            disabled={!selectedScenarios.length}
                            opener={
                                <IconButton
                                    tooltip="Remove selected"
                                    disabled={!selectedScenarios.length}
                                >
                                    <DeleteIcon color={theme.colors.error} />
                                </IconButton>
                            }
                            actions={[
                                <FlatButton key={1} label="Cancel" />,
                                <FlatButton key={2} primary label="Remove" onClick={this.removeBulkScenario} />
                            ]}
                        >
                            Do you really want to remove these scenarios?
                        </Modal>
                        <Modal
                            opener={<IconButton><GroupIcon color={theme.colors.primary} /></IconButton>}
                            title="Groups"
                        >
                            <GroupManager userid={admin.id} onUpdate={() => this.fetchGroups()}/>
                        </Modal>
                    </div>
                </SelectedMenu>
                <Snackbar
                    open={this.state.openSnackbar}
                    message={this.state.snackbarMessage}
                    autoHideDuration={4000}
                    onRequestClose={this.closeSnackbar}
                />
            </Wrapper>
        )
    }

}

Scenarios.propTypes = {
    scenarios: PropTypes.array,
    peers: PropTypes.array,
    selectedScenario: PropTypes.object,
    setSelectedScenario: PropTypes.func.isRequired,
    removeScenario: PropTypes.func.isRequired,
    copyScenario: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
}

Scenarios.defaultProps = {
    scenarios: [],
    peers: [],
    selectedScenario: {},
}

export default Scenarios
