export default {
	'en-US': {
		'FAQ': 'FAQ',
		'false gamecode': 'False gamecode',
		'get pdf report': 'get pdf report',
		'choose different game code': 'choose different game code',
		'User name': 'User name',
		'Password': 'Password',
		'Email': 'Email',
		'Remember me': 'Remember me',
		'Forgot password': 'Forgot password',
		Cancel: 'Cancel',
		Recover: 'Recover',
		Login: 'Login',
		Logout: 'Logout',
		'Contacting admin': 'Contacting admin',
		'Log into admin section': 'Log into admin section',
		'ADMIN SECTION': 'ADMIN SECTION',
		'Quit game': 'Quit game',
		'Quit': 'Quit',
		'About the game': 'About the game',
		'Code copied to clipboard': 'Code copied to clipboard',
		'Quit current game?': 'Quit current game?',
		'All data will be lost!!!': 'All data will be lost!!!',
		'CODE': 'CODE',
		'TEST MICRO': 'TEST MICRO',
		'Test your microphone': 'Test your microphone',
		'Prepare for next round': 'Prepare for next round',
		'Go to next round': 'Go to next round',
		'QUESTION': 'QUESTION',
		'new question': 'new question',
		'New scenario': 'New scenario',
		'Remaining time': 'Remaining time',
		'Thank you for your participation!': 'Thank you for your participation!',
		'Finish game': 'Finish game',
		'Insert game code here': 'Insert game code here',
		'Display results': 'Display results',
		'Audio record': 'Audio record',
		'Image': 'Image',
		'Transcript': 'Transcript',
		'Transcript text': 'Transcript text',
		'Deeper analysis': 'Deeper analysis',
		'Most recent words': 'Most recent words',
		'Rankings': 'Rankings',
		'Please enter single word for each ranking': 'Please enter single word for each ranking',
		'Report': 'Report',
		'Download report': 'Download report',
		'Learning by imagination': 'Learning by imagination',
		'scenario code': 'scenario code',
		'Language': 'Language',
		'Start game now': 'Start game now',
		'Enter your name (optional)': 'Enter your name (optional)',
		'Start game': 'Start game',
		'legalCheckbox1': 'I agree the legal infos and conditions by sumo technologies gmbh, as described ',
		'legalCheckbox2': 'here',
		'legalCheckbox3': '',
		'Open feedback board': 'Open feedback board',
		'Powered by': 'Powered by',
		'Overview1': 'My games',
		'Overview2': 'Compare',
		'aboutthegame1': 'After the game has been started, questions must be answered in a spontaneous manner.',
		'aboutthegame2': 'Pictures or videos are displayed which relate to the questions. For each questions you have 45 seconds time.',
		'aboutthegame3': 'The voices are recorded and then evaluated later',
		'aboutthegame4': 'The answers should be in English.',
		'aboutthegame5.1': 'The evaluation is done via the ',
		'aboutthegame5.2': 'feedback portal',
		'aboutthegame5.3': ', by entering the displayed game code. Please remember the game code.',
		'aboutthegame6': 'In the feedback portal, the most frequently or most important words have to be placed in a rank order, according to the most important meaning.',
		'aboutthegame7': 'Imagio by sumo technologies gmbh, Wien. Mit der Verwendung des Spiels Imagio und der Nutzung aller Funktionen auf dieser Webseite werden die Nutzungsbedingungen der sumo technologies gmbh akzeptiert. Jede Haftung durch sumo technologies gmbh ist ausgeschlossen.',
	},
	'th-TH': {
		'FAQ': 'FAQ',
		'false gamecode': 'False gamecode',
		'get pdf report': 'get pdf report',
		'choose different game code': 'choose different game code',
		'User name': 'User name',
		'Password': 'Password',
		'Email': 'Email',
		'Remember me': 'Remember me',
		'Forgot password': 'Forgot password',
		Cancel: 'Cancel',
		Recover: 'Recover',
		Login: 'Login',
		Logout: 'Logout',
		'Contacting admin': 'Contacting admin',
		'Log into admin section': 'Log into admin section',
		'ADMIN SECTION': 'ADMIN SECTION',
		'Quit game': 'Quit game',
		'Quit': 'Quit',
		'About the game': 'About the game',
		'Code copied to clipboard': 'Code copied to clipboard',
		'Quit current game?': 'Quit current game?',
		'All data will be lost!!!': 'All data will be lost!!!',
		'CODE': 'CODE',
		'TEST MICRO': 'TEST MICRO',
		'Test your microphone': 'Test your microphone',
		'Prepare for next round': 'Prepare for next round',
		'Go to next round': 'Go to next round',
		'QUESTION': 'QUESTION',
		'new question': 'new question',
		'New scenario': 'New scenario',
		'Remaining time': 'Remaining time',
		'Thank you for your participation!': 'Thank you for your participation!',
		'Finish game': 'Finish game',
		'Insert game code here': 'Insert game code here',
		'Display results': 'Display results',
		'Audio record': 'Audio record',
		'Image': 'Image',
		'Transcript': 'Transcript',
		'Transcript text': 'Transcript text',
		'Deeper analysis': 'Deeper analysis',
		'Most recent words': 'Most recent words',
		'Rankings': 'Rankings',
		'Please enter single word for each ranking': 'Please enter single word for each ranking',
		'Report': 'Report',
		'Download report': 'Download report',
		'Learning by imagination': 'Learning by imagination',
		'scenario code': 'scenario code',
		'Language': 'Language',
		'Start game now': 'Start game now',
		'Enter your name (optional)': 'Enter your name (optional)',
		'Start game': 'Start game',
		'legalCheckbox1': 'I agree the legal infos and conditions by sumo technologies gmbh, as described ',
		'legalCheckbox2': 'here',
		'legalCheckbox3': '',
		'Open feedback board': 'Open feedback board',
		'Powered by': 'Powered by',
		'Overview1': 'My games',
		'Overview2': 'Compare',
		'aboutthegame1': 'After the game has been started, questions must be answered in a spontaneous manner.',
		'aboutthegame2': 'Pictures or videos are displayed which relate to the questions. For each questions you have 45 seconds time.',
		'aboutthegame3': 'The voices are recorded and then evaluated later',
		'aboutthegame4': 'The answers should be in English.',
		'aboutthegame5.1': 'The evaluation is done via the ',
		'aboutthegame5.2': 'feedback portal',
		'aboutthegame5.3': ', by entering the displayed game code. Please remember the game code.',
		'aboutthegame6': 'In the feedback portal, the most frequently or most important words have to be placed in a rank order, according to the most important meaning.',
		'aboutthegame7': 'Imagio by sumo technologies gmbh, Wien. Mit der Verwendung des Spiels Imagio und der Nutzung aller Funktionen auf dieser Webseite werden die Nutzungsbedingungen der sumo technologies gmbh akzeptiert. Jede Haftung durch sumo technologies gmbh ist ausgeschlossen.',
	},
	'vi-VN': {
		'FAQ': 'FAQ',
		'false gamecode': 'False gamecode',
		'get pdf report': 'get pdf report',
		'choose different game code': 'choose different game code',
		'User name': 'User name',
		'Password': 'Password',
		'Email': 'Email',
		'Remember me': 'Remember me',
		'Forgot password': 'Forgot password',
		Cancel: 'Cancel',
		Recover: 'Recover',
		Login: 'Login',
		Logout: 'Logout',
		'Contacting admin': 'Contacting admin',
		'Log into admin section': 'Log into admin section',
		'ADMIN SECTION': 'ADMIN SECTION',
		'Quit game': 'Quit game',
		'Quit': 'Quit',
		'About the game': 'About the game',
		'Code copied to clipboard': 'Code copied to clipboard',
		'Quit current game?': 'Quit current game?',
		'All data will be lost!!!': 'All data will be lost!!!',
		'CODE': 'CODE',
		'TEST MICRO': 'TEST MICRO',
		'Test your microphone': 'Test your microphone',
		'Prepare for next round': 'Prepare for next round',
		'Go to next round': 'Go to next round',
		'QUESTION': 'QUESTION',
		'new question': 'new question',
		'New scenario': 'New scenario',
		'Remaining time': 'Remaining time',
		'Thank you for your participation!': 'Thank you for your participation!',
		'Finish game': 'Finish game',
		'Insert game code here': 'Insert game code here',
		'Display results': 'Display results',
		'Audio record': 'Audio record',
		'Image': 'Image',
		'Transcript': 'Transcript',
		'Transcript text': 'Transcript text',
		'Deeper analysis': 'Deeper analysis',
		'Most recent words': 'Most recent words',
		'Rankings': 'Rankings',
		'Please enter single word for each ranking': 'Please enter single word for each ranking',
		'Report': 'Report',
		'Download report': 'Download report',
		'Learning by imagination': 'Learning by imagination',
		'scenario code': 'scenario code',
		'Language': 'Language',
		'Start game now': 'Start game now',
		'Enter your name (optional)': 'Enter your name (optional)',
		'Start game': 'Start game',
		'legalCheckbox1': 'I agree the legal infos and conditions by sumo technologies gmbh, as described ',
		'legalCheckbox2': 'here',
		'legalCheckbox3': '',
		'Open feedback board': 'Open feedback board',
		'Powered by': 'Powered by',
		'Overview1': 'My games',
		'Overview2': 'Compare',
		'aboutthegame1': 'After the game has been started, questions must be answered in a spontaneous manner.',
		'aboutthegame2': 'Pictures or videos are displayed which relate to the questions. For each questions you have 45 seconds time.',
		'aboutthegame3': 'The voices are recorded and then evaluated later',
		'aboutthegame4': 'The answers should be in English.',
		'aboutthegame5.1': 'The evaluation is done via the ',
		'aboutthegame5.2': 'feedback portal',
		'aboutthegame5.3': ', by entering the displayed game code. Please remember the game code.',
		'aboutthegame6': 'In the feedback portal, the most frequently or most important words have to be placed in a rank order, according to the most important meaning.',
		'aboutthegame7': 'Imagio by sumo technologies gmbh, Wien. Mit der Verwendung des Spiels Imagio und der Nutzung aller Funktionen auf dieser Webseite werden die Nutzungsbedingungen der sumo technologies gmbh akzeptiert. Jede Haftung durch sumo technologies gmbh ist ausgeschlossen.',
	},
	'de-DE': {
		'FAQ': 'FAQ',
		'false gamecode': 'Falsches Gamecode',
		'get pdf report': 'Öffne Report',
		'choose different game code': 'Bitte anderen Gamecode wählen',
		'User name': 'Benutzername',
		'Password': 'Passwort',
		'Email': 'E-mail',
		'Remember me': 'merken',
		'Forgot password': 'Passwort vergesssen',
		Cancel: 'Abbrechen',
		Recover: 'Wiederherstellen',
		Login: 'Login',
		Logout: 'Logout',
		'Contacting admin': 'Admin kontaktieren',
		'Log into admin section': 'Bei Admin anmelden',
		'ADMIN SECTION': 'ADMIN',
		'Quit game': 'Spiel beenden',
		'Quit': 'Beenden',
		'About the game': 'Über Imagio',
		'Code copied to clipboard': 'Code in Zwischenablage kopiert',
		'Quit current game?': 'Spiel wirklich beenden?',
		'All data will be lost!!!': 'Alle Daten gehen verloren!!!',
		'CODE': 'CODE',
		'TEST MICRO': 'TEST MIKRO',
		'Test your microphone': 'Test Mikrofon',
		'Prepare for next round': 'Vorbereiten auf nächste Frage',
		'Go to next round': 'Weiter',
		'QUESTION': 'FRAGE',
		'new question': 'NEUE FRAGE',
		'New scenario': 'Neues Szenario',
		'Remaining time': 'Zeit übrig',
		'Thank you for your participation!': 'Danke für die Teilnahme!',
		'Finish game': 'Spiel abschließen',
		'Insert game code here': 'Gamecode eingeben',
		'Display results': 'Ergebnisse anzeigen',
		'Audio record': 'Audio Aufnahme',
		'Image': 'Bild',
		'Transcript': 'Transkript',
		'Transcript text': 'Transkript Text',
		'Deeper analysis': 'Analysen',
		'Most recent words': 'Häufigste Worte',
		'Rankings': 'Rankings',
		'Please enter single word for each ranking': 'Bitte einzelnes Wort pro Ranking eingeben',
		'Report': 'Report',
		'Download report': 'Download Report',
		'Learning by imagination': 'Learning by imagination',
		'scenario code': 'Scenario Code',
		'Language': 'Sprache',
		'Start game now': 'Spiel starten',
		'Enter your name (optional)': 'Name eingeben (optional)',
		'Start game': 'Starte Spiel',
		'legalCheckbox1': 'Ich akzeptiere die Nutzungsbedingungen und Datenschutzbestimmungen der sumo technologies gmbh, siehe ',
		'legalCheckbox2': 'hier',
		'legalCheckbox3': ' ',
		'Open feedback board': 'Feedback Portal',
		'Powered by': 'Powered by',
		'Overview1': 'Meine Spiele',
		'Overview2': 'Vergleichen',
		'aboutthegame1': 'Nach dem Start des Spiels müssen Fragen spontan beantwortet werden.',
		'aboutthegame2': 'Es werden Bilder oder Videos angezeigt, die sich auf die Fragen beziehen. Für jede Frage haben Sie eine gewisse Anzahl Sekunden Zeit. ',
		'aboutthegame3': 'Die Stimmen werden aufgezeichnet und später ausgewertet',
		'aboutthegame4': 'Die Antworten sollten auf Englisch oder Deutsch sein (je nach Einstellung).',
		'aboutthegame5.1': 'Die Auswertung erfolgt über das',
		'aboutthegame5.2': 'Feedback-Portal ',
		'aboutthegame5.3': 'durch Eingabe des angezeigten Spielcodes. Bitte merke dir den Spielcode.',
		'aboutthegame6': '',
		'aboutthegame7': 'Imagio von sumo Technologies gmbh, Wien. Mit der Verwendung des Spiels Imagio und der Nutzung aller Funktionen auf dieser Webseite werden die Nutzungsbedingungen der Sumo Technologies GmbH, Wien, akzeptiert. Jede Haftung durch sumo Technologies gmbh ist ausgeschlossen.',
	}
}
