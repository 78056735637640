import React from 'react'
import { BrowserRouter } from "react-router-dom"

import styled from 'styled-components'

import background from './assets/header-bg.jpg'

import TopMenu from './components/TopMenu/container'
import ScreenArea from './components/screens/'

import Snackbar from './components/components/Snackbar/container'

const Wrapper = styled.div`
	width: 100%;
	min-height: 100vh;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
`

const Main = () => (
	<BrowserRouter>
    	<Wrapper style={{backgroundImage: `url(${background})`}}>
        	<TopMenu />
        	<ScreenArea />
    	</Wrapper>
    	<Snackbar />
    </BrowserRouter>
)

export default Main
