import { combineReducers } from 'redux'
// import { routerReducer } from 'react-router-redux'
import gameReducer from './gameReducer'
import resultReducer from './resultReducer'
import userReducer from './userReducer'
import usersReducer from './usersReducer'
import snackbarReducer from './snackbarReducer'
import languageReducer from './languageReducer'
import codesReducer from './codesReducer'

export default combineReducers({
  // routing: routerReducer,
  game: gameReducer,
  user: userReducer,
  users: usersReducer,
  result: resultReducer,
  snackbar: snackbarReducer,
  language: languageReducer,
  codes: codesReducer,
})
