import React from "react"
import PropTypes from 'prop-types'

import Jodit from 'jodit-react'

import TextField from 'material-ui/TextField'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import Chip from 'material-ui/Chip'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import Checkbox from 'material-ui/Checkbox'

import styled from 'styled-components'

import Dropzone from '../../../components/Dropzone/'

import Modal from '../../../components/Modal/'

const StyledModal = styled(Modal)`
    max-width: 700px !important;
`

// const WordsWrapper = styled.div`
//     display: none;
//     flex-wrap: wrap;
//     >* {
//         margin: 4px !important;
//     }
// `

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    >div:first-child {
        margin-bottom: 8px;
        width: 100%;
    }
`

const Stretch = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    >:first-child {
        display: flex;
        flex-direction: column;
    }
`

const SmallStuff = styled(Stretch)`
    justify-content: space-around;
    >* {
        width: auto !important;
        flex: 1 1 auto;
        margin: 0 8px;
    }
`

const TagWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 2;
    padding: 8px;
    margin: 0 8px;
    border: 1px solid #FAB808;
    border-radius: 4px;
    >* {
        margin-right: 8px !important;
        margin-bottom: 8px !important;
    }
`

const EditorWrapper = styled.div`
    height: 200px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 12px;
`

const DropzoneWrapper = styled.div`
    min-width: 200px;
    max-width: 50%;
`

const getDefaultState = props => ({
    question: props.question || '',
    videoUrl: props.videourl || '',
    isVideo: false,
    tags: props.tags ? props.tags.split(',') : [],
    newTag: '',
    level: props.qlevel,
    words: props.words || '',
    duration: props.duration,
    wordcount: props.wordcount,
    questiontype: props.questiontype || 'tracking',
    showImage: props.showImage,
    showText: props.showText,
    showRecordingSymbol: props.showRecordingSymbol,
})

class MakeQuestion extends React.Component {

    state = getDefaultState(this.props)

    updateShowImage = () => this.setState(prev => ({ showImage: !prev.showImage }))
    updateShowText = () => this.setState(prev => ({ showText: !prev.showText }))
    updateShowRecordingSymbol = () => this.setState(prev => ({ showRecordingSymbol: !prev.showRecordingSymbol }))
    updateDuration = (e) => this.setState({ duration: e.target.value })
    updateWordcount = (e) => this.setState({ wordcount: e.target.value })
    updateLevel = (e) => this.setState({ level: e.target.value })
    updateWords = (words) => this.setState({ words })
    updateQuestion = (e) => this.setState({ question: e.target.value })
    updateQuestionType = (e, i, questiontype) => this.setState({ questiontype })
    updateNewTag = (e) => this.setState({ newTag: e.target.value })
    updateVideoUrl = (videoUrl, isVideo) => this.setState({ videoUrl, isVideo })
    addTag = () => {
        const tags = [...this.state.tags, this.state.newTag]
        this.setState({ newTag: '', tags })
    }
    removeTag = (tag) => {
        const tags = this.state.tags.filter(t => t !== tag)
        this.setState({ tags })
    }

    saveQuestion = () => {
        this.props.makeQuestion(
            this.props.scenario.id,
            this.props.scenario.questions.length,
            this.state.question,
            this.state.isVideo,
            this.state.videoUrl,
            this.state.tags,
            this.state.words,
            this.props.questionid,
            this.state.level,
            this.state.duration,
            this.state.wordcount,
            this.state.questiontype,
            this.state.showImage,
            this.state.showText,
            this.state.showRecordingSymbol,
        )
        if (this.props.resetOnSend) {
            this.setState(getDefaultState(this.props))
        }
    } 

    render() {
        const { question, tags, newTag, level, words, duration, wordcount, questiontype, showImage, showText, showRecordingSymbol, videoUrl } = this.state
        const { title, opener, scenario, translations } = this.props
        if (!scenario || !scenario.id) return null
        return (
            <StyledModal
                title={`${title} question`}
                opener={opener || <RaisedButton primary fullWidth label={translations['new question']} />}
                disabled={!scenario || !scenario.id}
                actions={[
                    <FlatButton key={1} label="Cancel" />,
                    <FlatButton key={2} label={title} primary onClick={this.saveQuestion} disabled={!question} />
                ]}
            >
                <Wrapper>
                    <Stretch>
                        <div>
                            <TextField
                                style={{width: 'auto', marginRight: 8}}
                                floatingLabelText="Question"
                                multiLine
                                value={question}
                                onChange={this.updateQuestion}
                            />
                            <SelectField
                                style={{width: 'auto', marginRight: '8px'}}
                                floatingLabelText="Question type"
                                value={questiontype}
                                onChange={this.updateQuestionType}
                            >
                                <MenuItem value={'tracking'} primaryText="Tracking" />
                                <MenuItem value={'trackingnext'} primaryText="Tracking next" />
                                <MenuItem value={'display'} primaryText="Display" />
                                <MenuItem value={'displaynext'} primaryText="Display next" />
                            </SelectField>
                            <SmallStuff>
                                <TextField
                                    floatingLabelText="Level"
                                    value={level}
                                    onChange={this.updateLevel}
                                />
                                <TextField
                                    floatingLabelText="Duration in sec:"
                                    value={duration}
                                    onChange={this.updateDuration}
                                />
                                <TextField
                                    floatingLabelText="Wordcount"
                                    value={wordcount}
                                    onChange={this.updateWordcount}
                                />
                            </SmallStuff>
                            <Checkbox
                                iconStyle={{margin: 0}}
                                label="show image"
                                checked={showImage}
                                onCheck={this.updateShowImage}
                            />
                            <Checkbox
                                iconStyle={{margin: 0}}
                                label="show text"
                                checked={showText}
                                onCheck={this.updateShowText}
                            />
                            <Checkbox
                                iconStyle={{margin: 0}}
                                label="show recording symbol"
                                checked={showRecordingSymbol}
                                onCheck={this.updateShowRecordingSymbol}
                            />
                        </div>
                        <DropzoneWrapper>
                            <Dropzone targetFolder="game_images" setUrl={this.updateVideoUrl} url={videoUrl} takesYT />
                        </DropzoneWrapper>
                    </Stretch>
                    <EditorWrapper>
                        <Jodit
                            value={words}
                            config={{
                                height: 150,
                                showWordsCounter: false,
                                showCharsCounter: false,
                                allowResizeY: false,
                            }}
                            tabIndex={1}
                            onBlur={this.updateWords} // preferred to use only this option to update the content for performance reasons
                            // onChange={newContent => {}}
                        />
                    </EditorWrapper>
                    <Stretch>
                        <div>
                            <TextField
                                floatingLabelText="Add tag"
                                value={newTag}
                                onChange={this.updateNewTag}
                            />
                            <FlatButton primary fullWidth label="Add" onClick={this.addTag} />
                        </div>
                        <TagWrapper>
                            {tags.map(tag => (
                                <Chip
                                    key={tag}
                                    onRequestDelete={() => this.removeTag(tag)}
                                >
                                    {tag}
                                </Chip>
                            ))}
                        </TagWrapper>
                    </Stretch>
                </Wrapper>
            </StyledModal>
        )
    }

}

MakeQuestion.propTypes = {
    opener: PropTypes.any,
    makeQuestion: PropTypes.func.isRequired,
    isvideo: PropTypes.any,//bool,
    title: PropTypes.string,
    question: PropTypes.string,
    videourl: PropTypes.string,
    tags: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    scenario: PropTypes.object,
    questionnr: PropTypes.number,
    questionid: PropTypes.any,
    words: PropTypes.string,
    qlevel: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    duration: PropTypes.any,//number,
    wordcount: PropTypes.any,//number,
    questiontype: PropTypes.string,
    showImage: PropTypes.bool,
    showText: PropTypes.bool,
    showRecordingSymbol: PropTypes.bool,
    translations: PropTypes.object.isRequired,
    resetOnSend: PropTypes.bool,
};

MakeQuestion.defaultProps = {
    opener: null,
    scenario: {},
    question: '',
    questionnr: -1,
    videourl: '',
    title: 'Create',
    isvideo: false,
    words: '',
    // tags: '',
    qlevel: 0,
    duration: 30,
    wordcount: 5,
    questiontype: 'tracking',
    showText: true,
    showImage: true,
    showRecordingSymbol: true,
    resetOnSend: false,
};

export default MakeQuestion;
