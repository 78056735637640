export default {
	colors: {
		primary: '#FAB808',
		darkGray: '#2d2d2d',
		lightGray: '#bdbdbd',
		transparentGray: 'rgba(200, 200, 200, 0.3)',
		transparentWhite: 'rgba(256, 256, 256, 0.9)',
		error: '#D32F2F',
	},
	shadows: {
		basic: 'box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.75)',
	}
}