import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';

import './style.scss';

class TextSave extends Component {

    state = {
        text: this.props.text
    };

    onChange = event => this.setState({text: event.target.value});

    save = () => this.props.onSave(this.state.text);

    render() {
        return (
            <div className="text-save">
                <TextField
                    id={`${this.props.title}-text-save`}
                    floatingLabelText={this.props.title}
                    multiLine={this.props.multiLine}
                    fullWidth
                    value={this.state.text}
                    onChange={this.onChange}
                />
                <FlatButton label={this.props.label} primary onClick={this.save} disabled={this.state.text === this.props.text} />
            </div>
        );
    }
}

TextSave.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    label: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    multiLine: PropTypes.bool
};

TextSave.defaultProps = {
    multiLine: false,
    label: 'save'
};

export default TextSave;
