import React from 'react'
import PropTypes from 'prop-types'

import RaisedButton from 'material-ui/RaisedButton'

import styled from 'styled-components'
// import theme from '../../../../stylesheets/vars'

import Single from './Single/container'

const Wrapper = styled.div`
    width: 100%;
`

class Admins extends React.Component {

    createUser = () => {
        this.props.createUser()
        this.props.completeCreateUser()
    }
    render() {
        const { users, admin } = this.props
        return (
            <Wrapper>
                {users.map(user =>
                    <Single key={user.id} user={user} isAdmin={user.id === admin.id} canDelete={admin.id == 1} />
                )}
                {admin.id == 1 &&
                    <RaisedButton primary fullWidth label="New user in this company" onClick={this.createUser} />
                }
            </Wrapper>
        )
    }

}            

Admins.propTypes = {
    admin: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    createUser: PropTypes.func.isRequired,
    completeCreateUser: PropTypes.func.isRequired,
}

Admins.defaultProps = {
}

export default Admins
