import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom' 
import arrayMove from 'array-move'

import styled from 'styled-components'
import RaisedButton from 'material-ui/RaisedButton'
import './style.scss'



import Speech from '../../components/Speech/'
import Pulse from '../../components/Pulse/index'
import MediaPlayer from '../../components/MediaWithError/'
import TextToSpeech from '../../components/TextToSpeech/'

import config from '../../../config'

const LogoImage = styled.div`
    position: absolute;
    top: 0;
    left: 5px;
    width: 100px;
    z-index: 1000;
    >* {
        width: 100%;
    }
`

let questions = []
let step = 0
let answeringStep = 0

class Game extends React.Component {

    state = {
        time: this.props.limit,
        awaitNextRound: false,
    }

    UNSAFE_componentWillMount() {
        if (!this.props.isGameRunning) {
            this.props.history.push(config.linkUrl)
            return
        }
        questions = this.props.gameState.questions
    }
 
    componentDidMount() {
        window.mediaRecorder.addEventListener('dataavailable', this.storeAudioChunks)
        this.startAnsweringQuestion()
    }

    componentWillUnmount() {
        this.stopAnsweringQuestion()
        window.mediaRecorder.removeEventListener('dataavailable', this.storeAudioChunks)
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (!props.isGameRunning) {
            this.stopAnsweringQuestion()
            return
        }
        if (props.gameState.step !== this.props.gameState.step) {
            step = props.gameState.step
            answeringStep = this.props.gameState.step
            if (props.gameState.step >= questions.length) return
            this.startAnsweringQuestion()
        }
    }

    storeAudioChunks = event => {
        const blob = new Blob([event.data], { 'type' : 'audio/wav' })
        const question = questions[answeringStep]
        this.props.saveAudio(
            blob,
            this.props.gameState.gameId,
            answeringStep,
            // question.questionnr,
            question.questionid,
            this.props.resetTranscript(),
            this.props.language
        )
    }

    startAnsweringQuestion = () => {
        const question = questions[step]

        if (question.questiontype === 'displaynext') return

        if (question.questiontype.includes('tracking')) {
            window.mediaRecorder.start()
            const prevQuestion = questions[step - 1]
            if (prevQuestion && !prevQuestion.questiontype.includes('tracking')) this.props.resetTranscript()
        }
        if (question.questiontype.includes('next')) return
        this.setState({time: question.duration || this.props.limit})
        this.timer = setInterval(() => {
            const time = this.state.time
            if (time < 1) {
                this.next()
                return
            }
            this.setState({ time: time - 1 })
        }, 1000)
    }

    stopAnsweringQuestion = () => {
        const question = questions[step]
        if (question) {
            const matchCount = this.props.transcript.split(' ').filter(word => {
                if (!word) return false
                if (question.tags && question.tags.split(',').map(w => w.toLowerCase()).includes(word.toLowerCase())) return true
                if (question.words && question.words.split(',').map(w => w.toLowerCase()).includes(word.toLowerCase())) return true
                return false
            }).length
            if (question.wordcount && matchCount && matchCount >= parseInt(question.wordcount)) {
                if (this.props.gameState.scenario.levelLogic === 'all questions') {
                    const matches = questions.filter((q, index) => index > step && q.qlevel >= question.qlevel)
                    if (matches.length) {
                        const lowestQLevel = matches.map(q => q.qlevel).sort()[0]
                        const matchingQuestion = questions.findIndex((q, index) => index > step && q.qlevel === lowestQLevel)
                        arrayMove.mutate(questions, matchingQuestion, step + 1)
                    }
                } else {
                    const greater = questions.filter((q, index) => index > step && q.qlevel > question.qlevel)
                    if (greater.length) {
                        const lowestQLevel = greater.map(q => q.qlevel).sort()[0]
                        const matchingQuestion = questions.findIndex((q, index) => index > step && q.qlevel === lowestQLevel)
                        arrayMove.mutate(questions, matchingQuestion, step + 1) 
                    } else {
                        const equals = questions.findIndex((q, index) => index > step && q.qlevel === question.qlevel)
                        if (equals > -1) {
                            arrayMove.mutate(questions, equals, step + 1) 
                        }
                    }
                }
            }
        }
        try {
            window.mediaRecorder.stop()
        } catch(e) {
            // console.log('mediaRecorder.stop error: ', e)
        }
        clearInterval(this.timer)
    }

    next = () => {
        answeringStep = step
        this.stopAnsweringQuestion()
        if (questions[step + 1] && questions[step].round < questions[step + 1].round) {
            this.setState({ awaitNextRound: true })
            return
        }
        if (this.state.awaitNextRound) this.setState({ awaitNextRound: false })
        this.props.next()
    }

    finish = () => {
        this.props.history.push(config.linkUrl)
        this.props.finish()
    }

    render() {
        const { time, awaitNextRound } = this.state
        const { translations, gameState } = this.props
        return (
            <div className="game-screen">
                {gameState.scenario.logo &&
                    <LogoImage><MediaPlayer src={gameState.scenario.logo} /></LogoImage>
                }
                {step < questions.length &&
                    <React.Fragment>
                        {awaitNextRound ? (
                            <div className="questions">
                                <h2>{translations['Prepare for next round']}</h2>
                                <RaisedButton label={translations['Go to next round']} primary onClick={this.next} fullWidth />
                            </div>
                        ) : (
                            <React.Fragment>
                                {questions[step].showImage &&
                                    <div className="images">
                                        <MediaPlayer src={questions[step].videourl} autoPlay />
                                    </div>
                                }
                                <div className="questions">
                                    {questions[step].showText &&
                                        <React.Fragment>
                                            <div className="status">{translations['QUESTION']}: {(step + 1)}</div>
                                            <div className="question">{questions[step].question}</div>
                                            {gameState.scenario.textToSpeech !== 'notalk' &&
                                                <TextToSpeech text={questions[step].question} autoPlay={gameState.scenario.textToSpeech==='auto'} />
                                            }
                                        </React.Fragment>
                                    }
                                    {questions[step].showRecordingSymbol &&
                                        <Pulse />
                                    }
                                    {questions[step].questiontype.includes('next') &&
                                        <RaisedButton label={translations['Go to next round']} primary onClick={this.next} fullWidth />
                                    }
                                    {(gameState.scenario.countdown && !questions[step].questiontype.includes('next')) &&
                                        <div className="time">{translations['Remaining time']}: {time}</div>
                                    }
                                    {(false && questions[step].questiontype === 'tracking') &&
                                        <Pulse />
                                    }
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                }
                {step >= questions.length &&
                    <div className="questions">
                        <h2>{translations['Thank you for your participation!']}</h2>
                        <RaisedButton label={translations['Finish game']} primary onClick={this.finish} fullWidth />
                    </div>
                }
            </div>
        )
    }

}

Game.propTypes = {
    gameState: PropTypes.object.isRequired,
	isGameRunning: PropTypes.bool.isRequired,
    next: PropTypes.func.isRequired,
    finish: PropTypes.func.isRequired,
    saveAudio: PropTypes.func.isRequired,
    limit: PropTypes.number,
    language: PropTypes.string.isRequired,
    translations: PropTypes.object.isRequired,
    // router
    history: PropTypes.object.isRequired,
    // speech
    transcript: PropTypes.string,
    resetTranscript: PropTypes.func.isRequired,
};

Game.defaultProps = {
    limit: 45,
    transcript: '',
};

export default withRouter(Speech(Game))
