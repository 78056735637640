import { connect } from 'react-redux'
import TheComponent from './index'

import actions from '../../../actions'
import { getAdmin } from '../../../selectors'

const mapStateToProps = (state) => ({
    admin: getAdmin(state),
})

const mapDispatchToProps = (dispatch) => ({
	getScenarios: () => dispatch({type: actions.GET_SCENARIOS}),
	getUsers: () => dispatch({type: actions.GET_USERS}),
})

const Contained = connect(
    mapStateToProps,
    mapDispatchToProps
)(TheComponent)

export default Contained
