import RandomCodeLib from 'random-codes'
import { excludedWords, videoFormats } from './constants'

const RC = new RandomCodeLib({
    chars: '0123456789',
    parts: 1,
    part_size: 5,
})

export const RandomCode = () => RC.generate()

export const questionSorter = (a, b) => {
	if (a.questionnr > b.questionnr) return 1
	if (a.questionnr < b.questionnr) return -1
	return 0
}

export const countWords = text =>  text.split(' ').filter(word => !excludedWords.includes(word)).reduce( (acc, curr) => {
	if (!curr.replace(/\s/g, '').length) return acc
	const existingIndex = acc.findIndex( ex => ex.text === curr)
	if (existingIndex < 0) return [...acc, {text: curr, count: 1}]
	const ret = [...acc]
	ret[existingIndex].count += 1
	return ret
}, []).sort( (a, b) => {
	if (a.count < b.count) return 1
	if (a.count > b.count) return -1
	return 0
})

export const checkIsVideo = src => videoFormats.includes(src.split('.').slice(-1)[0]) || src.includes('youtube.com')
