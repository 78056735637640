import React from 'react'
import PropTypes from 'prop-types'

import { CopyToClipboard } from 'react-copy-to-clipboard'

import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'

import { withRouter } from 'react-router-dom'

import styled from 'styled-components'
import theme from '../../stylesheets/vars'

import logo from '../../assets/sumo.png'

import Modal from '../components/Modal/container'
import AboutTheGame from '../AboutTheGame/container'
import LoginAdmin from '../LoginAdmin/container'

import MicTest from '../components/MicTest/'

import config from '../../config'

const Wrapper = styled.div`
    width: calc(100% - 40px);
    max-width: 1200px;
    margin: 0 auto;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    >div:nth-child(2) {
        background: rgba(255, 255, 255, 0.7);
        height: 100%;
        border-radius: 4px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        >* {
            margin: 0 10px;
        }
    }
`

const Image = styled.img`
    max-width: 250px;
`

const GameCode = styled.div`
    font-weight: bold;
    background: ${theme.colors.transparentGray};
    padding: 5px;
    color: black;
    border-radius: 8px;
`

class TopMenu extends React.Component {

    componentDidUpdate(prevProps) {
        // fix this
        if (prevProps.isGameRunning !== this.props.isGameRunning) {
            if (this.props.isGameRunning) this.props.history.push('/game')
            if (!this.props.isGameRunning) window.location.href = config.linkUrl
        }
    }

    render() {
        const { isGameRunning, quitGame, gameState, openSnackbar, history, translations } = this.props
        return (
            <Wrapper>
                <a href="https://imagio.sumo-technologies.com/"><Image src={logo} alt='logo' /></a>

                <div>
                    {isGameRunning ? (
                        <React.Fragment>
                            <CopyToClipboard text={gameState.code} onCopy={() => openSnackbar(translations['Code copied to clipboard'])}>
                                <GameCode>{translations['CODE']}: {gameState.code}</GameCode>
                            </CopyToClipboard>
                            {gameState.step < gameState.questions.length &&
                                <Modal
                                    opener={<RaisedButton label={translations['Quit game']} secondary />}
                                    title={translations['Quit current game?']}
                                    actions={[
                                        <FlatButton key={1} label={translations['Cancel']} />,
                                        <FlatButton key={2} label={translations['Quit']} onClick={quitGame} />,
                                    ]}
                                >
                                    {translations['All data will be lost!!!']}
                                </Modal>
                            }
                        </React.Fragment>
                	) : (
                        <React.Fragment>
                            <Modal opener={<FlatButton label={translations['FAQ']} />} title={translations['FAQ']}>
                                Here you will find a FAQ information later.
                            </Modal>
                            <Modal opener={<FlatButton label={translations['About the game']} />} title={translations['About the game']}>
                                <AboutTheGame history={history} />
                            </Modal>
                            <Modal opener={<FlatButton label={translations['TEST MICRO']} />} title={translations['Test your microphone']}>
                                <MicTest />
                            </Modal>
                            <LoginAdmin />
                        </React.Fragment>
                    )}
                </div>
            </Wrapper>
        )
    }
}

TopMenu.propTypes = {
	quitGame: PropTypes.func.isRequired,
	gameState: PropTypes.object.isRequired,
	isGameRunning: PropTypes.bool.isRequired,
    openSnackbar: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    translations: PropTypes.object.isRequired,
}

TopMenu.defaultProps = {
}

export default withRouter(TopMenu)
