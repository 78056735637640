// import React from 'react'
// import PropTypes from 'prop-types'

import styled from 'styled-components'
import theme from '../../../stylesheets/vars'

export const Link = styled.span`
	color: ${theme.colors.primary};
	cursor: pointer;
`
