import { connect } from 'react-redux'
import TheComponent from './index'

import actions from '../../../../actions/'
import {getSelectedScenario, getTranslations} from '../../../../selectors/'

const mapStateToProps = (state) => ({
    scenario: getSelectedScenario(state),
    translations: getTranslations(state),
})

const mapDispatchToProps = (dispatch) => ({
	makeQuestion: (scenarioid, questionnr, question, isvideo, videourl, tags, words,
		questionid, qlevel, duration, wordcount, questiontype, showImage, showText, showRecordingSymbol) =>
		dispatch({type: actions.MAKE_QUESTION, scenarioid, questionnr, question, isvideo, videourl, tags, words,
			questionid, qlevel, duration, wordcount, questiontype, showImage, showText, showRecordingSymbol}),
})

const Contained = connect(
    mapStateToProps,
    mapDispatchToProps
)(TheComponent)

export default Contained
