import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import Chip from 'material-ui/Chip'
import FlatButton from 'material-ui/FlatButton'
import IconButton from 'material-ui/IconButton'
import DeleteIcon from 'material-ui/svg-icons/action/delete'
import EditIcon from 'material-ui/svg-icons/image/edit'
import CopyIcon from 'material-ui/svg-icons/content/content-copy'
import UpIcon from 'material-ui/svg-icons/navigation/arrow-upward'
import DownIcon from 'material-ui/svg-icons/navigation/arrow-downward'

import MediaWithError from '../../../components/MediaWithError/'

import Modal from '../../../components/Modal/'
import MakeQuestion from '../MakeQuestion/container'

import theme from '../../../../stylesheets/vars'

const QuestionWrapper = styled.div`
    border: 2px solid ${theme.colors.primary};
    border-radius: 4px;
    display: flex;
    align-items: stretch;
    width: calc(100% - 12px);
    margin: 8px 0;
    padding: 8px 0 8px 8px;
    background: ${props => props.active ? theme.colors.transparentGray : 'none'};
    >* {
        width: 40%;
        flex: 1 1 auto;
        img {
            width: 100%;
        }
        &:nth-child(2) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 0 16px;
            width: calc(40% - 32px);
        }
        &:last-child {
            width: 48px;
            flex: 0 0 auto;
        }
    }
`

const Title = styled.div`
    border-bottom: 1px dotted ${theme.colors.primary};
`

const TagsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    >* {
        margin: 4px !important;
    }
`

class Question extends React.Component {

    state = {
        imageError: false,
    }

    removeQuestion = questionId => this.props.removeQuestion(questionId)
    copyQuestion = questionId => this.props.copyQuestion(questionId)
    moveUp = questionId => this.props.moveUp(questionId)
    moveDown = questionId => this.props.moveDown(questionId)
    
    render() {
        const { isDragging, isLast } = this.props
        const { questionnr, question, isvideo, videourl, questionid, tags, words, qlevel, wordcount, duration, questiontype, showImage, showText, showRecordingSymbol } = this.props.question

        return (
            <QuestionWrapper active={isDragging}>
                <div>
                    <MediaWithError src={videourl} isVideo={isvideo != 0} />
                </div>
                <div>
                    <Title>{questionnr}. {question}</Title>
                    {tags && (
                        <TagsWrapper>
                            {tags.split(',').map(tag =>
                                <Chip key={ tag }>{ tag }</Chip>
                            )}
                        </TagsWrapper>
                    )}
                </div>
                <div>
                    <MakeQuestion
                        opener={
                            <IconButton>
                                <EditIcon color={theme.colors.primary} />
                            </IconButton>
                        }
                        title="Edit"
                        question={question}
                        questionnr={questionnr}
                        videourl={videourl}
                        isvideo={isvideo}
                        tags={tags}
                        words={words}
                        questionid={questionid}
                        qlevel={qlevel}
                        wordcount={wordcount}
                        duration={duration}
                        questiontype={questiontype}
                        showImage={showImage}
                        showText={showText}
                        showRecordingSymbol={showRecordingSymbol}
                    />
                    <Modal
                        opener={
                            <IconButton>
                                <CopyIcon color={theme.colors.primary} />
                            </IconButton>
                        }
                        title="Copy this question?"
                        actions={[
                            <FlatButton key={1} label="Cancel" />,
                            <FlatButton key={2} primary label="copy" onClick={() => this.copyQuestion(questionid)} />
                        ]}
                    >
                        Do you want to copy this question?
                    </Modal>
                    <Modal
                        title="Remove question?"
                        opener={
                            <IconButton>
                                <DeleteIcon color={theme.colors.error} />
                            </IconButton>
                        }
                        actions={[
                            <FlatButton key={1} label="Cancel" />,
                            <FlatButton key={2} primary label="Remove" onClick={() => this.removeQuestion(questionid)} />
                        ]}
                    >
                        Do you really want to remove this question?
                    </Modal>
                    {questionnr != 1 &&
                        <IconButton onClick={() => this.moveUp(questionid)}><UpIcon color={theme.colors.primary}/></IconButton>
                    }
                    {!isLast &&
                        <IconButton onClick={() => this.moveDown(questionid)}><DownIcon color={theme.colors.primary} /></IconButton>
                    }
                </div>
            </QuestionWrapper>
        )
    }

}

Question.propTypes = {
    question: PropTypes.object.isRequired,
    removeQuestion: PropTypes.func.isRequired,
    copyQuestion: PropTypes.func.isRequired,
    moveUp: PropTypes.func.isRequired,
    moveDown: PropTypes.func.isRequired,
    isDragging: PropTypes.bool,
    isLast: PropTypes.bool,
}

Question.defaultProps = {
    isDragging: false,
    isLast: false,
}

export default Question
