export const languages = [
    {short: 'en-US', name: 'English'},
    {short: 'de-DE', name: 'Deutsch'},
    {short: 'th-TH', name: 'Thai'},
    {short: 'vi-VN', name: 'Vietnamese'},
]

export const videoFormats = ['mp4', 'WEBM', 'MPG', 'MP2', 'MPEG', 'MPE', 'MPV', 'OGG', 'MP4', 'M4P', 'M4V', 'AVI', 'WMV', 'MOV', 'QT', 'FLV', 'SWF', 'AVCHD']

export const excludedWords = [
    'the',
    'a',
    'this',
    'then',
    'and',
    'so',
    'or',
    'in',
    'at',
    'on',
    'for',
    'to',
    'by',
    'der',
    'die',
    'das',
    'und',
    'oder',
    'weil',
    'deshalb',
    'nach',
    'da',
    'ist',
    'doch',
]

