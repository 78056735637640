import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import SnackbarUI from 'material-ui/Snackbar'

const Snackbar = ({ message, clear }) => {
  const [open, setOpen] = useState(false)
  const [m, setMessage] = useState(false)

  useEffect(() => {
    if (!open && message) {
      setMessage(message)
      setOpen(true)
    }
    if (open && !message) {
      setOpen(false)
    }
  })

  return (
    <SnackbarUI
      open={open}
      message={m}
      autoHideDuration={4000}
      onRequestClose={clear}
    />
  )
}

Snackbar.propTypes = {
  message: PropTypes.string,
  clear: PropTypes.func.isRequired,
}

Snackbar.defaultProps = {
  message: '',
}

export default Snackbar
