import { connect } from 'react-redux'
import TheComponent from './index';

import actions from '../../actions';
import {getAdmin, getTranslations} from '../../selectors';

const mapStateToProps = (state) => ({
	admin: getAdmin(state),
	translations: getTranslations(state),
})

const mapDispatchToProps = (dispatch) => ({
	login: (username, password, remember, sessionHash) => dispatch({type: actions.LOGIN_ADMIN, username, password, remember, sessionHash}),
	logout: () => dispatch({type: actions.LOGOUT_ADMIN})
})

const Contained = connect(
    mapStateToProps,
    mapDispatchToProps
)(TheComponent)

export default Contained;