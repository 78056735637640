import { connect } from 'react-redux'
import TheComponent from './index'

import actions from '../../../actions/'

const mapStateToProps = (state) => ({
	...state.snackbar,
})

const mapDispatchToProps = (dispatch) => ({
	clear: () => dispatch({ type: actions.OPEN_SNACKBAR, message: '' }),
})

const Contained = connect(
    mapStateToProps,
    mapDispatchToProps
)(TheComponent)

export default Contained
