import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Link, withRouter } from 'react-router-dom'
// import config from '../../config'

import TextField from 'material-ui/TextField'
import FlatButton from 'material-ui/FlatButton'
import Checkbox from 'material-ui/Checkbox'
import Snackbar from 'material-ui/Snackbar'

import Modal from '../components/Modal/'

import './style.scss'
import theme from '../../stylesheets/vars'

import config from '../../config'

const OptionsWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 12px;
    label {
        font-size: 12px;
        color: ${theme.colors.lightGray} !important;
    }
`

const ForgotPassword = styled.span`
    font-size: 12px;
    color: ${theme.colors.lightGray};
    text-transform: uppercase;
    cursor: pointer;
    white-space: nowrap;
    flex-shrink: 0;
`

const defaultState = {
    username: '',
    password: '',
    email: '',
    remember: false,
    forgotPasswordActive: false,
    snackbarOpen: false,
}

class LoginAdmin extends React.Component {

    constructor(props) {
        super(props)
        if (!props.admin || !props.admin.id) {
            const savedUser = localStorage.getItem('admin')
            if (savedUser) {
                props.login(false, false, true, JSON.parse(savedUser).sessionHash)
            } // else if (props.history.location.pathname === '/backend') {
                // props.history.push(config.linkUrl)
            // }
        }
        this.state = { ...defaultState }
    }

    reset = () => this.setState({ ...defaultState })

    updateUsername = e => this.setState({ username: e.target.value })
    updatePassword = e => this.setState({ password: e.target.value })
    updateEmail = e => this.setState({ email: e.target.value })
    updateRemember = () => this.setState(prev => ({ remember: !prev.remember }))
    
    toggleForgotPassword = () => this.setState(prev => ({ forgotPasswordActive: !prev.forgotPasswordActive }))
    handleCloseSnackbar = () => this.setState({ snackbarOpen: false })

    recover = (close) => {
        if (this.state.forgotPasswordActive) {
            this.setState({ snackbarOpen: true })
            setTimeout(() => close(), 4000)
        }
    }

    login = () => {
        this.props.login(this.state.username, this.state.password, this.state.remember)
        if (!this.state.remember) localStorage.removeItem('admin')
    }

    logout = () => {
        localStorage.removeItem('admin')
        sessionStorage.removeItem('persist:root')
        this.props.logout()   
    }

    createActions = () => {
        const { translations } = this.props
        if (this.state.forgotPasswordActive) {
            return [
                <FlatButton
                    key={1}
                    label={translations['Cancel']}
                    onClickCallbackClose={this.toggleForgotPassword}
                />,
                <FlatButton
                    key={2}
                    label={translations['Recover']}
                    primary
                    disabled={!this.state.email}
                    onClickCallbackClose={this.recover}
                />
            ]
        }
        return [
            <FlatButton key={1} label={translations['Cancel']} />,
            <FlatButton
                key={2}
                label={translations['Login']}
                primary
                disabled={!this.state.password || !this.state.username}
                onClickCallbackClose={this.login}
            />
        ]
    }

    gotoAdmin = () => {
        this.props.history.push('/backend')
    }

    componentDidUpdate(oldProps) {
        const { admin } = this.props
        if ((!oldProps.admin || !oldProps.admin.id) && admin && admin.id) {
            if (this.state.remember) {
                localStorage.setItem('admin', JSON.stringify(admin))
            }
        }
        if (oldProps.admin && oldProps.admin.id && (!admin || !admin.id)) {
            window.location.href = config.linkUrl
            // this.props.history.push(config.linkUrl)
        }
    }

    getErrorText = match => {
        const { admin } = this.props
        if (!admin.error || admin.data !== match || !admin.message || typeof admin.message !== 'string') return ''
        return admin.message
    }

    render() {
        const { username, password, email, remember, forgotPasswordActive, snackbarOpen } = this.state
        const { admin, translations } = this.props
        if (admin && admin.id) {
            return (
                <React.Fragment>
                    <Link to="backend"><FlatButton label={translations['ADMIN SECTION']} /></Link>
                    <FlatButton secondary onClick={this.logout} label={translations['Logout']} />
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <Snackbar
                    open={snackbarOpen}
                    message={translations['Contacting admin']}
                    autoHideDuration={4000}
                    onRequestClose={this.handleCloseSnackbar}
                />
                <Modal
                    opener={<FlatButton label="admin" />}
                    title={translations['Log into admin section']}
                    closeOnAction
                    onOpen={this.reset}
                    className="login-admin-modal"
                    actions={this.createActions()}
                >
                    {!forgotPasswordActive ? (
                        <div className="login-admin">
                            <TextField
                                floatingLabelText={translations['User name']}
                                value={username} 
                                onChange={this.updateUsername}
                                fullWidth
                                errorText={this.getErrorText('username')}
                            />
                            <TextField
                                floatingLabelText={translations['Password']}
                                value={password} 
                                onChange={this.updatePassword}
                                type="password"
                                fullWidth
                                errorText={this.getErrorText('password')}
                            />
                        </div>
                    ) : (
                        <TextField
                            floatingLabelText={translations['Email']}
                            fullWidth
                            value={email} 
                            onChange={this.updateEmail}
                        />
                    )}
                    <OptionsWrapper>
                        {!forgotPasswordActive &&
                            <React.Fragment>
                                <Checkbox
                                    iconStyle={{margin: 0}}
                                    label={translations['Remember me']}
                                    checked={remember}
                                    onCheck={this.updateRemember}
                                />
                                <ForgotPassword onClick={this.toggleForgotPassword}>{translations['Forgot password']}</ForgotPassword>
                            </React.Fragment>
                        }
                    </OptionsWrapper>
                </Modal>
            </React.Fragment>
        );
    }

}

LoginAdmin.propTypes = {
    login: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    admin: PropTypes.any,
    translations: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    // router: PropTypes.object.isRequired,
}
LoginAdmin.defaultProps = {
    admin: undefined,
}

export default withRouter(LoginAdmin)
