import React from 'react'
import PropTypes from 'prop-types'

import TextField from 'material-ui/TextField'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import IconButton from 'material-ui/IconButton'
import ShuffleIcon from 'material-ui/svg-icons/av/shuffle'
import Checkbox from 'material-ui/Checkbox'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'

import styled from 'styled-components'

import Modal from '../../../components/Modal/'
import Dropzone from '../../../components/Dropzone/'
import { RandomCode } from '../../../../utils/'

import { makeGetRequest } from '../../../../api/'

const StyledModal = styled(Modal)`
    max-width: 500px !important;
`

const Wrapper = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    >:first-child {
        display: flex;
        flex-direction: column;
    }
    >:last-child {
        margin-left: 8px;
    }
`

const Stretch = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    >:first-child {
        width: calc(100% - 48px) !important;
    }
`



class MakeScenario extends React.Component {

    state = {
        name: this.props.name || '',
        code: this.props.code || '',
        countdown: !!this.props.countdown,
        levelLogic: this.props.levelLogic,
        textToSpeech: this.props.textToSpeech,
        logo: this.props.logo || '', 
        groupid: this.props.groupid, 
        groups: [],
    }

    getGroups = () => {
        console.log('getGroups')
        makeGetRequest('getScenarioGroups.php', {userid: this.props.admin.id}).then(res => this.setState({ groups: res.data }))
    }

    updateName = (e) => this.setState({name: e.target.value})
    updateCode = (e) => this.setState({code: e.target.value})
    updateCountdown = () => this.setState(prev => ({countdown: !prev.countdown}))
    updateLevelLogic = (e, i, levelLogic) => this.setState({ levelLogic })
    updateLogo = logo => this.setState({ logo })
    updateTextToSpeech = (e, i, textToSpeech) => this.setState({ textToSpeech })
    updateGroup = (e, i, groupid) => this.setState({ groupid: parseInt(groupid) })

    generateRandomCode = () => this.setState({
        code: RandomCode()
    })

    makeScenario = () => this.props.makeScenario(this.state.name, this.state.code, this.state.countdown, this.state.levelLogic, this.state.logo, this.state.textToSpeech, this.props.id, this.state.groupid)

    render() {
        const { name, code, countdown, levelLogic, logo, textToSpeech, groupid, groups } = this.state
        const { title, opener, translations } = this.props
        return (
            <StyledModal
                title={`${title} scenario`}
                opener={opener || <RaisedButton primary fullWidth label={translations['New scenario']} />}
                onOpen={this.getGroups}
                actions={[
                    <FlatButton label="Cancel" key={1} />,
                    <FlatButton key={2} label={title} primary onClick={this.makeScenario} disabled={!name || !code} />
                ]}
            >
                <Wrapper>
                    <div>
                        <TextField
                            floatingLabelText="Name of scenario"
                            value={name}
                            onChange={this.updateName}
                        />
                        <Stretch>
                            <TextField
                                floatingLabelText="Code"
                                value={code}
                                onChange={this.updateCode}
                            />
                            <IconButton tooltip="Random code" onClick={this.generateRandomCode}><ShuffleIcon /></IconButton>
                        </Stretch>
                        <Checkbox
                            iconStyle={{margin: 0}}
                            label="Show countdown"
                            checked={countdown}
                            onCheck={this.updateCountdown}
                        />
                        <SelectField
                            floatingLabelText="Level logic"
                            value={levelLogic}
                            onChange={this.updateLevelLogic}
                        >
                            <MenuItem value="all questions" primaryText="All questions" />
                            <MenuItem value="answered questions" primaryText="Answered questions" />
                        </SelectField>
                        <SelectField
                            floatingLabelText="Text to speech"
                            value={textToSpeech}
                            onChange={this.updateTextToSpeech}
                        >
                            <MenuItem value="notalk" primaryText="No talk" />
                            <MenuItem value="talk" primaryText="Talk" />
                            <MenuItem value="auto" primaryText="Auto-talk" />
                        </SelectField>
                        <SelectField
                            floatingLabelText="Group"
                            value={groupid.toString()}
                            onChange={this.updateGroup}
                        >
                            <MenuItem value="0" primaryText="All groups" />
                            {groups.map(g =>
                                <MenuItem key={g.id} value={g.id} primaryText={g.name} />
                            )}
                        </SelectField>
                    </div>
                    <Dropzone targetFolder="scenario_images" setUrl={this.updateLogo} url={logo} takesVideo={false} />
                </Wrapper>
            </StyledModal>
        )
    }

}

MakeScenario.propTypes = {
    opener: PropTypes.any,
    makeScenario: PropTypes.func.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
    title: PropTypes.string,
    levelLogic: PropTypes.string,
    logo: PropTypes.string,
    textToSpeech: PropTypes.string,
    countdown: PropTypes.bool,
    groupid: PropTypes.number,
    groups: PropTypes.array.isRequired,
    admin: PropTypes.object.isRequired,
    translations: PropTypes.object.isRequired,
};

MakeScenario.defaultProps = {
    opener: null,
    id: '',
    name: '',
    code: '',
    title: 'Create',
    levelLogic: '',
    logo: '',
    textToSpeech: 'talk',
    countdown: true,
    groupid: 0,
};

export default MakeScenario;
