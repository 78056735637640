import React from 'react'

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import theme from './stylesheets/vars'

import CircularProgress from 'material-ui/CircularProgress'

import { Provider } from 'react-redux'

import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store'

import Main from './Main'

const muiTheme = getMuiTheme({
	palette: {
		primary1Color: theme.colors.primary,
		// accent1Color: '#FAB808',
	},
	textField: {
		backgroundColor: 'transparent',
	},
	checkbox: {
		boxColor: theme.colors.lightGray,
	},
})

const App = () => (
    <Provider store={store}>
        <MuiThemeProvider muiTheme={muiTheme}>
        	<PersistGate
        		loading={<CircularProgress size={60} thickness={7} color={theme.colors.primary} />}
        		persistor={persistor}
        	>
				<Main />
            </PersistGate>
        </MuiThemeProvider>
    </Provider>
)

export default App
