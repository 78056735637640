import axios from 'axios';
import config from '../config';

export const makePostRequest = (urlExtension, data = {}) => {
	const params = new FormData();
	Object.keys(data).forEach(d => params.append(d, data[d]));
	return axios.post(config.baseUrl + urlExtension, params, {
		withCredentials: true,
    	headers: {'Content-Type': 'multipart/form-data'}
	});
}

export const makeGetRequest = (urlExtension, data = {}) => 
	axios.get(`${config.baseUrl + urlExtension  }?${  Object.entries(data).map(d => `${d[0]}=${d[1]}`).join('&')}` );

export default {
	getDoesUsernameExist: (params) => makeGetRequest('getConfigOfGame.php', params),
	getCodes: (params) => makePostRequest('getCodes.php', params),
	loginAdmin: (params) => makePostRequest('loginAdmin.php', params),
	logoutAdmin: (params) => makePostRequest('logoutAdmin.php', params),
	makeQuestion: (params) => makePostRequest('makeQuestion.php', params),
	removeQuestion: (params) => makePostRequest('removeQuestion.php', params),
	copyQuestion: (params) => makePostRequest('copyQuestion.php', params),
	moveUpQuestion: (params) => makePostRequest('moveUpQuestion.php', params),
	moveDownQuestion: (params) => makePostRequest('moveDownQuestion.php', params),
	makeScenario: (params) => makePostRequest('makeScenario.php', params),
	removeScenario: (params) => makePostRequest('removeScenario.php', params),
	copyScenario: (params) => makePostRequest('copyScenario.php', params),
	getScenarios: (params) => makePostRequest('getScenarios.php', params),
	updateUser: (params) => makePostRequest('updateUser.php', params),
	createUser: (params) => makePostRequest('createUser.php', params),
	deleteUser: (params) => makePostRequest('deleteUser.php', params),
	getUsers: (params) => makePostRequest('getUsers.php', params),
	getGame: (params) => makeGetRequest('getConfigOfGame.php', params),
	saveRecording: (params) => makePostRequest('upload_record.php', params),
	getResults: (params) => makeGetRequest('getResults.php', params),
	saveTranscript: (params) => makePostRequest('saveTranscript.php', params),
	saveRankings: (params) => makePostRequest('saveRanks.php', params)
}