import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers/index'
import sagas from './sagas/index'

import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['user', 'location'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const middlewares = []

const sagaMiddleware = createSagaMiddleware()
middlewares.push(sagaMiddleware)

let middleware = applyMiddleware(...middlewares)

if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
  middleware = compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
}

const store = createStore(persistedReducer, middleware)
const persistor = persistStore(store)
sagaMiddleware.run(sagas)

export { store, persistor }
