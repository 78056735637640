import { connect } from 'react-redux'
import TheComponent from './index';

import actions from '../../../../actions/';
import {getAdmin, getTranslations} from '../../../../selectors';

const mapStateToProps = (state) => ({
    admin: getAdmin(state),
    translations: getTranslations(state),
})

const mapDispatchToProps = (dispatch) => ({
	makeScenario: (name, code, countdown, levelLogic, logo, textToSpeech, id = 0, groupid) =>
		dispatch({type: actions.MAKE_SCENARIO, name, code, countdown, levelLogic, logo, textToSpeech, id, groupid})
})

const Contained = connect(
    mapStateToProps,
    mapDispatchToProps
)(TheComponent)

export default Contained;