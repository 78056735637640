import { connect } from 'react-redux'
import TheComponent from './index'

import actions from '../../../../actions'
import { getCodeError, getTranslations } from '../../../../selectors'

const mapStateToProps = (state) => ({
	errorText: getCodeError(state),
	translations: getTranslations(state),
})

const mapDispatchToProps = (dispatch) => ({
	getResults: gameCode => dispatch({type: actions.GET_RESULTS, gameCode})
})

const Contained = connect(
    mapStateToProps,
    mapDispatchToProps
)(TheComponent)

export default Contained
