import { connect } from 'react-redux'
import TheComponent from './index'

// import actions from '../../../actions'
import {getCodeExists} from '../../../selectors'

const mapStateToProps = (state) => ({
	codeExists: getCodeExists(state)
})

const mapDispatchToProps = (dispatch) => ({
})

const Contained = connect(
    mapStateToProps,
    mapDispatchToProps
)(TheComponent)

export default Contained
