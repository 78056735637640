import { combineReducers } from 'redux';
import actions from '../actions';

function codeExists(state='', action) {
	switch (action.type) {
		case `${actions.GET_RESULTS}_SUCCESS`: return !action.data.data.error
    case actions.REMOVE_RESULT_DATA:
    case actions.GET_RESULTS: return false
    default: return state
  }
}

function error(state = '', action) {
  switch (action.type) {
    case `${actions.GET_RESULTS}_SUCCESS`: {
      if (action.data.data.error) return action.data.data.message
      return ''
    }
    case actions.GET_RESULTS: return ''
    default: return state
  }
}

function data(state = {}, action) {
    switch (action.type) {
        case `${actions.GET_RESULTS}_SUCCESS`: return action.data.data
        case actions.REMOVE_RESULT_DATA: return {}
        case actions.SAVE_TRANSCRIPT: return {
            game: state.game,
            records: state.records.map(record => {
                if (action.recordid !== record.recordid) return record;
                return {
                   ...record,
                   speech: action.text
                }
            })
        }
        default: return state
    }
}

export default combineReducers({
  	codeExists,
    error,
  	data
});