import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import LegalInfo from '../LegalInfo/'

import {Link} from '../components/SmallElems/'

const Wrapper = styled.div`
	>div::before:not(:last-child) {
		content: "•";
		color: $primary;
		margin-right: 15px;
	}
	>div {
		margin-bottom: 15px;
	}
`

const AboutTheGame = ({ history, translations }) => (
    <Wrapper>
        <div>{translations['aboutthegame1']}</div>
        <div>{translations['aboutthegame2']}</div>
        <div>{translations['aboutthegame3']}</div>
        <div>{translations['aboutthegame4']}</div>
        <div>{translations['aboutthegame5.1']}<Link onClick={() => history.push('/feedback')}>{translations['aboutthegame5.2']}</Link>{translations['aboutthegame5.3']}</div>
        <div>{translations['aboutthegame6']}</div>
        <div>{translations['aboutthegame7']}</div>
        <LegalInfo />
    </Wrapper>
)

AboutTheGame.propTypes = {
	history: PropTypes.object.isRequired,
    translations: PropTypes.object.isRequired,
}

export default AboutTheGame
