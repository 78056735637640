import { combineReducers } from 'redux';
import actions from '../actions/';

function admin(state=false, action) {
	switch (action.type) {
		case `${actions.LOGIN_ADMIN}_SUCCESS`: {
			return action.data.data;
		}
		case actions.LOGOUT_ADMIN: {
			return false
		}
    default: return state
  }
}

function scenarios(state = [], action) {
	switch (action.type) {
		case `${actions.GET_SCENARIOS}_SUCCESS`: {
			return action.data.data.map(s => ({
				...s,
				countdown: s.countdown == 1,
				questions: s.questions.map(q => ({
					...q,
					showImage: q.showImage == 1,
            		showText: q.showText == 1,
            		showRecordingSymbol: q.showRecordingSymbol == 1,
				}))
			}))
		}
    	default: return state
	}
}

function selectedScenario(state = 0, action) {
	switch (action.type) {
		case actions.SET_SELECTED_SCENARIO: {
			return action.id
		}
    	default: return state
	}
}

export default combineReducers({
  	admin,
  	scenarios,
  	selectedScenario,
});