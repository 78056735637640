import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import {Tabs, Tab} from 'material-ui/Tabs'

import Scenarios from './Scenarios/container'
import Questions from './Questions/container'
import Admins from './Admins/container'

import theme from '../../../stylesheets/vars'

const StyledTabs = styled(Tabs)`
    >:first-child {
        background-color: transparent !important;
        >button {
            >div >div {
                background: transparent !important;
                color: ${theme.colors.primary};
            }
            &:first-child {
                border-right: 1px solid ${theme.colors.primary} !important;
            }
            &:last-child {
                border-left: 1px solid ${theme.colors.primary} !important;
            }
        }
    }
    >:nth-child(2) {
        margin-bottom: 10px !important;
    }
`
 
    
const ScenarioWrapper = styled.div`
    display: flex;
    width: 100%;
    >:first-child {
        width: 40%;
    }
    >:last-child {
        margin-left: 6px;
        border-left: 4px solid ${theme.colors.primary};
        padding-left: 6px;
        width: 60%;
    }
`

class Admin extends React.Component {

    constructor(props) {
        super(props)
        props.getScenarios()
        props.getUsers()
    }

    render() {
    

        if (!this.props.admin || !this.props.admin.id) return null
        return (
            <StyledTabs>
                <Tab label="Scenarios & Questions">
                    <ScenarioWrapper>
                        <Scenarios />
                        <Questions />
                    </ScenarioWrapper>
                </Tab>
                <Tab label="Admins">
                    <Admins />
                </Tab>
            </StyledTabs>
        )
    }

}
                

Admin.propTypes = {
    history: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    getScenarios: PropTypes.func.isRequired,
}

Admin.defaultProps = {
}

export default withRouter(Admin)
