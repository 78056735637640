import actions from '../actions'

const defaultState = {}

function snackbarReducer(state = defaultState, action) {
	switch (action.type) {
		case actions.OPEN_SNACKBAR: {
      return action
		}
    default: return state
  }
}

export default snackbarReducer