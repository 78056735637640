import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import {getLanguage} from '../../../selectors/'

const mapStateToProps = (state) => ({
     language: getLanguage(state),
})

const SpeechRecognition = SpeechRecognition || webkitSpeechRecognition
const recognition = new SpeechRecognition()
recognition.continuous = false
recognition.interimResults = true

let interim = ''
let shouldRestart = true

const SpeechHoc = (Component) => {
  class TheComponent extends React.Component {

    constructor(props) {
      super(props)
      this.state = {
        transcript: '',
      }

      shouldRestart = true

      recognition.lang = props.language
      recognition.onerror = e => console.log('speech error: ', e)

      recognition.onresult = event => {
          const isFinal = event.results[0].isFinal
          interim = event.results[0][0].transcript
          if (isFinal) {
              this.setState(prev => ({
                transcript: prev.transcript + ' ' + interim,
              }))
              interim = ''
          }
      }
      recognition.onend = () => {
          if (shouldRestart) {
            recognition.start()
          }
      }
      recognition.start()
    }

    componentWillUnmount() {
      shouldRestart = false
      recognition.stop()
      recognition.onresult = undefined
      recognition.onend = undefined
    }
    
    resetTranscript = () => {
      shouldRestart = false
      recognition.abort()
      const transcript = this.state.transcript + interim
      interim = ''
      this.setState({transcript: ''})
      try {
        recognition.start()
      } catch(e) {}
      shouldRestart = true
      return transcript
    }

    render() {
      const { transcript } = this.state
      return (
        <Component
          transcript={transcript}
          resetTranscript={this.resetTranscript}
          {...this.props}
        />
      )
    }
  }
  TheComponent.propTypes = {
    language: PropTypes.string.isRequired,
  }

  return connect(
    mapStateToProps,
  )(TheComponent)
}

export default SpeechHoc
