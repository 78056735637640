import { connect } from 'react-redux'
import TheComponent from './index'

import actions from '../../actions'
import {getIsGameRunning, getGameState, getTranslations } from '../../selectors'

const mapStateToProps = (state) => ({
     isGameRunning: getIsGameRunning(state),
     gameState: getGameState(state),
     translations: getTranslations(state),
})

const mapDispatchToProps = (dispatch) => ({
	quitGame: () => dispatch({type: actions.GAME_QUIT}),
	openSnackbar: (message) => dispatch({type: actions.OPEN_SNACKBAR, message}),
})

const Contained = connect(
    mapStateToProps,
    mapDispatchToProps
)(TheComponent)

export default Contained