import { connect } from 'react-redux'
import TheComponent from './index'

import actions from '../../../../../actions/'
// import {} from '../../../../../selectors/'

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
	updateUser: (id, username, password, fullname, email, logo) => dispatch({type: actions.UPDATE_USER, id, username, password, fullname, email, logo}),
	completeUpdateUser: () => dispatch({type: actions.OPEN_SNACKBAR, message: 'User successfully updated'}),
	deleteUser: (id) => dispatch({type: actions.DELETE_USER, id}),
	completeDeleteUser: () => dispatch({type: actions.OPEN_SNACKBAR, message: 'User successfully deleted'}),
})

const Contained = connect(
    mapStateToProps,
    mapDispatchToProps
)(TheComponent)

export default Contained
