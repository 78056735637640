import React from 'react'
import PropTypes from 'prop-types'

import Speech from 'react-speech'

import styled from 'styled-components'

import { connect } from 'react-redux'
import {getLanguage} from '../../../selectors/'

const mapStateToProps = (state) => ({
     lang: getLanguage(state),
})

const Wrapper = styled.div`
  width: auto;
  button:not(.rs-play) {
    display: none
  }
  button.rs-play {
    width: 50px;
    height: 50px;
  }
`

class TextToSpeech extends React.Component {

  speechRef = React.createRef()

  componentDidMount() {
    if (this.props.autoPlay) {
      this.speechRef.current.getElementsByClassName('rs-play')[0].click()
    }
  }

  render() {
    const { text, lang, autoPlay } = this.props
    return (
      <Wrapper ref={this.speechRef}>
        <Speech text={text} resume lang={lang} />
      </Wrapper>
    )
  }
}
TextToSpeech.propTypes = {
  text: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
}
TextToSpeech.defaultProps = {
  autoPlay: false,
}

export default connect(
    mapStateToProps,
)(TextToSpeech)
