import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FlatButton from 'material-ui/FlatButton';

import './style.scss';

class DropShow extends Component {
    state = {
        collapsed: true
    };

    toggle = () => {
        this.setState(prev => ({ collapsed: !prev.collapsed }));
        this.props.onToggle();
    };

    render() {
        return (
            <div className={`drop-show ${this.props.className}`}>
                <FlatButton onClick={this.toggle} label={this.props.openerText} fullWidth />
                <div className={`collapsible ${this.state.collapsed ? '' : 'open'}`}>{this.props.children}</div>
            </div>
        );
    }
}

DropShow.propTypes = {
    openerText: PropTypes.string,
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
    onToggle: PropTypes.func
};

DropShow.defaultProps = {
    openerText: 'Open',
    className: '',
    onToggle: () => {}
};

export default DropShow;
