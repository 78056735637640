import React from 'react'
// import PropTypes from 'prop-types'

import styled from 'styled-components'
import theme from '../../stylesheets/vars'

const Wrapper = styled.div`

`

const Title = styled.div`
	color: ${theme.colors.darkGray};
`

const MainTitle = styled.div`
	font-size: 20px;
	font-weight: bold;
`

const LegalInfo = () => (
	<Wrapper>
		<MainTitle>Information &uuml;ber die Verarbeitung personenbezogener Daten</MainTitle>

		<p>Mit 25. Mai 2018 ist die Verordnung des Europ&auml;ischen Parlaments
		und des Rates vom 27. April 2016 zum Schutz nat&uuml;rlicher Personen
		bei der Verarbeitung personenbezogener Daten, zum freien Datenverkehr
		und zur Aufhebung der Richtlinie 95/46/EG
		(Datenschutz-Grundverordnung, kurz DSGVO) in allen Mitgliedstaaten
		der Europ&auml;ischen Union unmittelbar anwendbar.</p>

		<p>Die DSGVO sieht unter anderem erweiterte Informationsverpflichtungen
		betreffend die Verarbeitung von personenbezogenen Daten vor.</p>
		
		<p>
		In Erf&uuml;llung dieser Verpflichtungen (insbesondere Artikel 13
		DSGVO) informieren wir Sie hiermit &uuml;ber die von uns
		durchgef&uuml;hrte(n) Verarbeitung(en) Ihrer personenbezogenen Daten.</p>

		<Title>1. Welche personenbezogenen Daten (kurz &bdquo;Daten&ldquo;) werden verarbeitet?</Title>
		<p>Audiodateien welche von &bdquo;Spielern&ldquo; aufgenommen und
		mithilfe einer Software zu Textdateien umgewandelt werden.</p>
		
		<Title>2. Zu welchem Zweck werden die Daten verarbeitet?</Title>
		<p>
		Im Zuge der Nutzung von &bdquo;Imagio&ldquo; wird auf Fragen
		bezogenes, gesprochenes Input (Antworten, Meinungen, Feedback) in
		geschriebenen Text umgewandelt und die am h&auml;ufigsten genannten
		Worte und Phrasen gelistet. Diese Listung erlaubt einen Vergleich mit
		den jeweiligen Fragen assoziierten &bdquo;Tags&ldquo; und somit eine
		Aussage &uuml;ber den Grad der &Uuml;bereinstimmung. Daraus lassen
		sich Antworten inhaltlich automatisiert auswerten und vergleichen. Da
		bei der Spracherkennung technisch (Mikrofonqualit&auml;t, technische
		Einstellungen, &hellip;) und personenbezogen (Aussprache, Lautst&auml;rke,
		Nebenger&auml;usche, Fachterminologie,  ...) Faktoren variieren,
		kommt es bei der Umwandlung zu Text zu entsprechenden Abweichungen
		zwischen dem tats&auml;chlich Gesprochenen und dem erkannten und
		verglichenen Text.</p>

		<Title>3. Auf Basis welcher Rechtsgrundlage werden die Daten verarbeitet?</Title>
		<p>&#9746; Art. 6 Abs. 1 lit. a DSGVO &ndash; Der betroffene Spieler hat
		seine/ihre Einwilligung zu der Verarbeitung der sie betreffenden
		personenbezogenen Daten f&uuml;r einen oder mehrere bestimmte Zwecke
		(durch ankreuzen der entsprechenden Option) gegeben</p>
		<p>&#9746; Art. 6 Abs. 1 lit. f DSGVO &ndash; erforderlich zur Wahrung
		folgender berechtigter Interessen von sumo Technologies oder eines
		Dritten (die Interessen oder Grundrechte und Grundfreiheiten der/s
		Betroffenen &uuml;berwiegen nicht): Gem&auml;&szlig; Art 89 DSGVO zu
		statistischen Forschungszwecken.</p>
	
		<Title>4. Findet im Zuge der Verarbeitung Profiling statt?</Title>
		<p>Nein</p>

		<Title>5. Werden die Daten g&auml;nzlich oder zum Teil an andere Personen/Einrichtungen &uuml;bermittelt?</Title>
		<p>Ja, ihre Daten werden im Zuge der Verarbeitung zu oben genanntem
		Zweck an folgende Empf&auml;nger &uuml;bermittelt: Die Audiodateien
		werden durch Google verarbeitet (konkret: in Textdateien umgewandelt)</p>

		<Title>6. Befinden sich die unter Punkt 5 genannten Empf&auml;nger
		au&szlig;erhalb der EU/des EWR bzw. handelt es sich dabei um eine
		internationale Organisation?</Title>
		<p>Ja</p>
		
		<Title>7. Wie lange werden die Daten gespeichert bzw. nach welchen Kriterien wird die Dauer der Speicherung festgelegt?</Title>
		<p>Audiodateien werden in &Uuml;bereinkunft mit dem Spieler bzw. dem
		Spieler vermittelnde Organisation gel&ouml;scht.</p>
	
		<Title>8. Welche Rechte haben Sie als Betroffene/r?</Title>
		<p>Ihnen stehen grunds&auml;tzlich die Rechte auf Auskunft,
		Berichtigung, L&ouml;schung, Einschr&auml;nkung, Daten&uuml;bertragbarkeit
		und Widerruf zu. Um diese Rechte geltend zu machen wenden Sie sich
		bitte an unseren Datenschutzbeauftragten (Kontaktdaten siehe Punkt
		9).</p>
		<p>Dar&uuml;ber hinaus haben Sie das Recht, allf&auml;llige Beschwerden bei der Datenschutzbeh&ouml;rde einzubringen.</p>
	
		<Title>9. Kontaktdaten der Verantwortlichen und ihres Datenschutzbeauftragten:</Title>
		<ul>
			<li>
				<Title>Verantwortliche</Title> 
				<div>Sumo Technologies GmbH</div>
				<div>Lindengasse 56</div>
				<div>1070 Wien</div>
				<br /><br />
			</li>
			<li>
				<Title>Datenschutzbeauftragte/r</Title> 
				<div>Mariusz Gasinksi</div>
				<div>Lindengasse 56</div>
				<div>1070 Wien</div>
				<div>mariusz@sumo-technologies.com</div>
				<br /><br />
			</li>
		</ul>
 
		<p>10. Imagio by sumo technologies gmbh, Wien. Mit der Verwendung
		des Spiels Imagio und der Nutzung aller Funktionen auf dieser
		Webseite werden die Nutzungsbedingungen der sumo technologies gmbh
		akzeptiert. Jede Haftung durch sumo technologies gmbh ist
		ausgeschlossen. Jeder Anspruch auf Schadenersatz gegen&uuml;ber der
		sumo technologies gmbh ist ausgeschlossen.</p>
	</Wrapper>
)

// LegalInfo.propTypes = {
	// history: PropTypes.object.isRequired,
// }

export default LegalInfo
