import { connect } from 'react-redux'
import TheComponent from './index'

import actions from '../../../../actions'
import { getResults, getTranslations } from '../../../../selectors'

const mapStateToProps = (state) => ({
	data: getResults(state),
	translations: getTranslations(state),
})

const mapDispatchToProps = (dispatch) => ({
	saveTranscript: (text, recordid) => dispatch({type: actions.SAVE_TRANSCRIPT, recordid, text}),
	changeCode: () => dispatch({type: actions.REMOVE_RESULT_DATA}),
	saveRankings: (texts, questionnr, gameid) => dispatch({
		type: actions.SAVE_RANKINGS,
		texts: JSON.stringify(texts),
		questionnr,
		gameid
	})
})

const Contained = connect(
    mapStateToProps,
    mapDispatchToProps
)(TheComponent)

export default Contained
