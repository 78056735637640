import React from 'react'
import PropTypes from 'prop-types'

import { Tabs, Tab } from 'material-ui/Tabs'
import TextField from 'material-ui/TextField'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'

import config from '../../../../config'

import DropShow from '../../../components/DropShow/index'
import TextSave from '../../../components/TextSave/index'
import MultiTextSave from '../../../components/MultiTextSave/index'
import MediaPlayer from '../../../components/MediaWithError/'

import { countWords } from '../../../../utils/'

import styled from 'styled-components'
import theme from '../../../../stylesheets/vars'

const QuestionWrapper = styled.div`
    width: calc(100% - 20px);
    padding: 10px 10px 20px;
    margin: 0 !important;
    &:hover {
        background: ${theme.colors.transparentGray};
    }
    overflow: hidden;
    .result-tab {
        min-height: 40px;
        background: white;
        padding: 20px;
        >div {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-width: 50%;
            }
        }
    }
    button >div >div {
        font-size: 12px !important;
    }
`

const Buttons = styled.div`
    display: flex;
    justify-content: space-between;

`

class ResultsView extends React.Component {

	state = {
		ranks: this.props.data.records.map(record => {
            const rank = record.rank ? [...record.rank] : []
            while(rank.length < 5) {
                rank.push({ word: '' })
            }
            return rank
        })
	}

    mappedRecords = ({data, translations, ...props}) => data.records.map( (question, index) => (
        <QuestionWrapper key={question['0']}>
            <DropShow openerText={`${(index+1)}. ${question.question.question}`}>
                <Tabs contentContainerClassName="result-tab">
                    <Tab label={translations['Transcript']}>
                        <TextSave
                            title={translations['Transcript text']}
                            text={question.speech}
                            multiLine
                            onSave={text => props.saveTranscript(text, question.recordid)}
                        />
                    </Tab>
                    <Tab label={translations['Deeper analysis']}>
                        <TextField disabled multiLine fullWidth value={question.watson} id={`${index}-deeper-analysis`} />
                    </Tab>
                    <Tab label={translations['Most recent words']}>
                        <div>
                            {countWords(question.speech).map( word =>
                                <div key={word.text}>{word.text} ({word.count})</div>
                            )}
                        </div>
                    </Tab>
                    <Tab label={translations['Audio record']}>
                        <audio controls src={`${config.baseUrl}record_429fndfljn345df/record_${question.gameid}_${question.questionnr}.wav`} />
                    </Tab>
                    <Tab label={translations['Image']}>
                        <MediaPlayer src={question.question.videourl} />
                    </Tab>
                    <Tab label={translations['Rankings']}>
                        <h2>{translations['Please enter single word for each ranking']}</h2>
                        <MultiTextSave
                            texts={this.state.ranks[index].map(r => r.word)}
                            onSave={texts => props.saveRankings(texts, question.questionnr, question.gameid)}
                        />
                    </Tab>
                </Tabs>
            </DropShow>
        </QuestionWrapper>
    ))

//                    <Tab label={translations['Report']}>
  //                      <FlatButton label={translations['Download report']} />
    //                </Tab>
    render() {
        const { translations, changeCode, data } = this.props
        return (
            <div>
                <Buttons>
                    <RaisedButton label={translations['choose different game code']} secondary onClick={changeCode} />
                    <FlatButton label={translations['get pdf report']} primary onClick={() => window.open(`${config.linkUrl}/ws/globalReport.php?gameid=${data.game.gamecode}`, "_blank")} />
                </Buttons>
                {this.mappedRecords(this.props)}
            </div>
        )
    }

}

ResultsView.propTypes = {
	data: PropTypes.object.isRequired,
    translations: PropTypes.object.isRequired,
    saveTranscript: PropTypes.func.isRequired,
    saveRankings: PropTypes.func.isRequired,
    changeCode: PropTypes.func.isRequired
};

export default ResultsView
